import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js'
import BidderMenubar from "./../../../../menubars/BidderMenubar"

export function CreateBidderActivity() {
  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")
  var username = sessionStorage.getItem("username")
  var logged_in_user_info = username+' ('+employee_id+') '+designation

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else if(designation !== 'bidder'){
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));

  }

  const handleSubmit = (event) => {
    event.preventDefault();

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    var value3 = document.getElementById("dropdown_id3");
    var getvalue3 = value3.options[value3.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT" || getvalue3 === "DEFAULT"){
      alert("Fill required fields.")
    }
    else {
      axios.post('/bidder_activity.php', inputs).then(function(response){
        //console.log(response.data);
        navigate('/bidderactivity/'); //After saving, redirect to list of users
      });
    }
  }

  return (
    <div className="pagePadding">
      <div className="left">
        <BidderMenubar />
      </div>
      <h1>Daily Bidder Activity</h1>
      <h2>(Update all entries at end of day)</h2>
      <div>
        <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
            <tbody>
                <tr>
                    <th>
                        <label>Employee ID*: </label>
                    </th>
                    <td>
                      <select name="employee_id" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                        <option value="DEFAULT" disabled>Choose</option>
                        <option value={employee_id}>{logged_in_user_info}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Project Name*: </label>
                   </th>
                   <td>
                       <input type="text" name="project_bid_name" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Source Platform (Upwork/Freelancer/etc.)*: </label>
                   </th>
                   <td>
                       <input type="text" name="source_platform" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Bid Points (if new bid)*: </label>
                   </th>
                   <td>
                       <input type="text" name="total_bid_points" onChange={handleChange} />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Client New(if not hired before)/Recurring(hired before)*: </label>
                   </th>
                   <td>
                       <select name="new_recurring_client" id="dropdown_id2" onChange={handleChange} defaultValue={'DEFAULT'} >
                         <option value="DEFAULT" disabled>Choose</option>
                         <option value="new">New</option>
                         <option value="recurring">Recurring</option>
                       </select>
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Client Name*: </label>
                   </th>
                   <td>
                       <input type="text" name="name_client_communicated" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Hire Status*: </label>
                   </th>
                   <td>
                     <select name="hire_successfull" id="dropdown_id3" onChange={handleChange} defaultValue={'DEFAULT'} >
                       <option value="DEFAULT" disabled>Choose</option>
                       <option value="hired">Hire</option>
                       <option value="communicating">Communicating</option>
                     </select>
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Project Assigned To Team Leader (Only If Hire): </label>
                   </th>
                   <td>
                       <select name="assigned_to_tl" id="dropdown_id4" onChange={handleChange} defaultValue={'DEFAULT'} >
                          <option value="DEFAULT" disabled>Choose Team Leader.</option>
                          {users
                            .filter(user => user.designation ==='team_leader')
                            .map((user, key) =>
                            <option key={key} value={user.employee_id}>{user.username} {user.employee_id} {user.designation}</option>
                          )}
                      </select>
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Comments About Entry (If any): </label>
                   </th>
                   <td>
                       <textarea name="comments" onChange={handleChange} />
                   </td>
                </tr>
                <tr>
                    <td colSpan="2" align ="right">
                        <button>Save</button>
                    </td>
                </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  )
}
