import { Link } from 'react-router-dom';

export function Logout() {

  //sessionStorage.removeItem('loggedIn')
  sessionStorage.clear();

  return (
    <>
      <h1>Logout</h1>
      <center>
        <br />
        <h4>You have been logged out!</h4>
        <br />
        <Link to='/login'>Login</Link>
      </center>
    </>
  )
}
