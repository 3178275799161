import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'

export function SalaryCalculation() {
  const[attendance,setAttendance] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")
  var e_id = "eid_"+employee_id

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getAttendance();
    }
    // eslint-disable-next-line
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const [approved_ctc, setApproved_ctc] = useState(null)
  const handler = () => {
    // eslint-disable-next-line
    {users
      .filter(user => user.employee_id === employee_id)
      // eslint-disable-next-line
      .map((user) =>
      {
        // eslint-disable-next-line
        const approved_ctc = setApproved_ctc(user.ctc_approved_by_admin)
      }
    )}
    getAttendance();
  }

  function getAttendance() {
    axios.get("/attendance.php").then(function(response){

      const newArray = response.data.map(obj => {
        const last_attendance_date = obj.date;
        const attendance = obj[e_id];
        const count_present = (attendance.match(/P/g) || []).length;
        const count_half_day = (attendance.match(/H/g) || []).length;
        const count_full_leave = (attendance.match(/L/g) || []).length;
        const count_short_leave = (attendance.match(/S/g) || []).length;

        var p1 = 0;
        var p2 = 0;
        var p3 = 0;
        if(count_full_leave >= 1){
          p1 = 1;
        }

        if(count_half_day >= 2){
          p2 = 2;
        } else {
          p2 = count_half_day;
        }

        if(count_short_leave >= 4){
          p3 = 4;
        }
        else {
          p3 = count_short_leave;
        }

        const total_present = count_present;

        const valid_days_from_leaves = p1 + p2 + p3;

        const total_days_valid = count_present + valid_days_from_leaves;
        const one_day_approved_ctc = approved_ctc/31
        const valid_days_ctc = one_day_approved_ctc * total_days_valid

        return { last_attendance_date, total_present, valid_days_from_leaves, total_days_valid, one_day_approved_ctc, valid_days_ctc }
      })

      setAttendance(newArray);
    })
  }

  return (
    <div className="pagePadding">
      <h1>CTC On Basis of Attendance</h1>
      <div>
        <div>
        Employee Id: {employee_id} <br />
        {users
          .filter(user => user.employee_id === employee_id)
          .map((user) =>
          <div>Name: {user.username}</div>
        )}
        </div>
        <div className="salary_page_colors"> <b>Approved CTC:</b>
        {users
          .filter(user => user.employee_id === employee_id)
          .map((user) =>
          <div> ₹{user.ctc_approved_by_admin}/- Only</div>
        )}
        </div>
        <table>
          <thead>
            <tr>
              <th>Until Date</th>
              <th>Marked Present</th>
              <th>Total Approved Leaves</th>
              <th>Total Days</th>
              <th className="salary_page_colors">One day CTC</th>
              <th className="salary_page_colors">Earned CTC</th>
              <th className="salary_page_colors"><button onClick={handler} >Click twice to Calculate CTC</button></th>
            </tr>
          </thead>
          <tbody>
            {attendance
              .map((atten, key) =>
              <tr key={key}>
                <td>{atten.last_attendance_date}</td>
                <td>{atten.total_present}</td>
                <td>{atten.valid_days_from_leaves}</td>
                <td>{atten.total_days_valid}</td>
                <td className="salary_page_colors">{atten.one_day_approved_ctc}</td>
                <td className="salary_page_colors">{atten.valid_days_ctc}</td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <center><b>Important:</b> If there is any bonus/fine, etc. they will be added/deducted from this salary at end of month. <br />
          Company has the right to (hold/not pay) the CTC/salary, if anything found against employee. <br />
        </center>
      </div>
    </div>
  )
}
