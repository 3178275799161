import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, {baseURL} from '../../../../api/axios.js'

function MaintenanceWorkerProjects() {
  const[activities,setActivities] = useState([]);

  var navigate = useNavigate();
  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null && (designation !== 'maintenance_worker' || designation !== 'admin')){
      navigate('/login');
    }
    else {
      getActivities();
    }
  }, [loggedIn, designation, navigate]);

  function getActivities() {
        axios.get('/maintenance_activity.php').then(function(response){
        console.log(response.data);
        setActivities(response.data);
    });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  return (
    <div className="pagePadding">
      <h1>List Projects</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Project Created By</th>
              <th>Problem Date</th>
              <th>Problem Subject</th>
              <th>Problem Description</th>
              <th>Problem Photo</th>
              <th>Manual Inspection By</th>
              <th>Work Status</th>
              <th>Created On</th>
              <th>Updated On</th>
            </tr>
          </thead>
          <tbody>
            {activities
              //.filter(project => project.project_assigned_to_tl === employee_id)
              .map((maintenance_activity, key) =>
              <tr key={key}>
                {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
                {maintenance_activity.employee_id === employee_id || designation === 'admin' ? ( <>
                  <td>{maintenance_activity.work_id}</td>
                  <td>
                  {users
                    .filter(user => user.employee_id === maintenance_activity.employee_id)
                    .map((user, key) =>
                    <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                  )}
                  </td>                  <td>{maintenance_activity.problem_date}</td>
                  <td>{maintenance_activity.problem_subject}</td>
                  <td>{maintenance_activity.problem_description}</td>
                  <td><a href={baseURL+"/images/maintenance_images/"+maintenance_activity.problem_photos} target="_blank" rel="noreferrer"><img src={baseURL+"/images/maintenance_images/"+maintenance_activity.problem_photos} alt="activity_images" width="60" height="60" /></a></td>
                  <td>
                  {users
                    .filter(user => user.employee_id === maintenance_activity.manual_inspection_by)
                    .map((user, key) =>
                    <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                  )}
                  </td>
                  <td>{maintenance_activity.work_status}</td>
                  <td>{maintenance_activity.created_at}</td>
                  <td>{maintenance_activity.updated_at}</td>
                </> ) : ("")}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MaintenanceWorkerProjects;
