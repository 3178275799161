import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ViewTeams() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")
  var team = sessionStorage.getItem("team")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        //setUsers(response.data);
        // eslint-disable-next-line
        const newArray = response.data.map(obj => {
          if(designation === "admin"){
            if(obj.status === 'active' && (obj.designation === "maintenance_worker" || obj.designation === "human_resource")){
              const employee_id = obj.employee_id;
              const username = obj.username;
              const designation = obj.designation;
              const team_allocated = obj.team_allocated;
              const experience = obj.experience;
              const education = obj.education;
              const date_of_birth = obj.date_of_birth;
              const ctc_approved_by_admin = obj.ctc_approved_by_admin;
              const email = obj.email;
              const mobile = obj.mobile;
              const other_phone = obj.other_phone;
              const home_address = obj.home_address;
              const correspondence_address = obj.correspondence_address;
              const joining_date = obj.joining_date;
              const status = obj.status;
              const created_at = obj.created_at;

              return { employee_id, username, designation, team_allocated, experience, education, ctc_approved_by_admin, email, mobile, other_phone, date_of_birth, home_address, correspondence_address, joining_date, status, created_at }
            }
          }
          else if(designation === "project_manager"){
            if(obj.status === 'active' && (obj.designation === "team_leader" || obj.designation === "software_developer" || obj.designation === "software_designer" || obj.designation === "bidder" || obj.designation === "human_resource")){
              const employee_id = obj.employee_id;
              const username = obj.username;
              const designation = obj.designation;
              const team_allocated = obj.team_allocated;
              const experience = obj.experience;
              const education = obj.education;
              const date_of_birth = obj.date_of_birth;
              const ctc_approved_by_admin = obj.ctc_approved_by_admin;
              const email = obj.email;
              const mobile = obj.mobile;
              const other_phone = obj.other_phone;
              const home_address = obj.home_address;
              const correspondence_address = obj.correspondence_address;
              const joining_date = obj.joining_date;
              const status = obj.status;
              const created_at = obj.created_at;

              return { employee_id, username, designation, team_allocated, experience, education, ctc_approved_by_admin, email, mobile, other_phone, date_of_birth, home_address, correspondence_address, joining_date, status, created_at }
            }
          }
          else if(designation === "team_leader"){
            if(obj.status === 'active' && (obj.team_allocated === team)){
              const employee_id = obj.employee_id;
              const username = obj.username;
              const designation = obj.designation;
              const team_allocated = obj.team_allocated;
              const experience = obj.experience;
              const education = obj.education;
              const date_of_birth = obj.date_of_birth;
              const ctc_approved_by_admin = obj.ctc_approved_by_admin;
              const email = obj.email;
              const mobile = obj.mobile;
              const other_phone = obj.other_phone;
              const home_address = obj.home_address;
              const correspondence_address = obj.correspondence_address;
              const joining_date = obj.joining_date;
              const status = obj.status;
              const created_at = obj.created_at;

              return { employee_id, username, designation, team_allocated, experience, education, ctc_approved_by_admin, email, mobile, other_phone, date_of_birth, home_address, correspondence_address, joining_date, status, created_at }
            }
          }
          else if(designation === "assistant"){
            const employee_id = obj.employee_id;
            const username = obj.username;
            const designation = obj.designation;
            const team_allocated = obj.team_allocated;
            const experience = obj.experience;
            const education = obj.education;
            const date_of_birth = obj.date_of_birth;
            const ctc_approved_by_admin = obj.ctc_approved_by_admin;
            const email = obj.email;
            const mobile = obj.mobile;
            const other_phone = obj.other_phone;
            const home_address = obj.home_address;
            const correspondence_address = obj.correspondence_address;
            const joining_date = obj.joining_date;
            const status = obj.status;
            const created_at = obj.created_at;

            return { employee_id, username, designation, team_allocated, experience, education, ctc_approved_by_admin, email, mobile, other_phone, date_of_birth, home_address, correspondence_address, joining_date, status, created_at }
          }
        })
      //console.log(newArray)
      setUsers(newArray);
    });
  }

  const[pageNumber, setPageNumber] = useState(0)
  const usersPerPage = 5
  const pageVisited = pageNumber * usersPerPage
  const pageCount = Math.ceil(users.length / usersPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayteams = users
  .filter(user => user)
  .slice(pageVisited, pageVisited + usersPerPage)
  .map((user, key) =>
    <tr key={key}>
      <td>{user.employee_id}</td>
      <td>{user.team_allocated}</td>
      <td>{user.username}</td>
      <td>{user.designation}</td>
      <td>{user.experience}</td>
      <td>{user.education}</td>
      <td>{user.date_of_birth}</td>
      <td>{user.email}</td>
      <td>{user.mobile}</td>
      <td>{user.other_phone}</td>
      <td>{user.home_address}</td>
      <td>{user.correspondence_address}</td>
      <td>{user.joining_date}</td>
      <td>{user.ctc_approved_by_admin}</td>
      <td>{user.status}</td>
      <td>{user.created_at}</td>
    </tr>
  )



  if(designation === 'admin' || designation === 'project_manager' || designation === 'assistant' || designation === 'team_leader'){
    return (
      <div className="pagePadding">
        <h1>
          View Team
        </h1>
        <div className="right"> <b>Welcome {username}</b> </div>
          <br />
          <div className="right"> <b>{designation}</b> </div>
          <br />
          <div className="right"> <b>Employee Id:</b> {employee_id} </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Employee Id</th>
                <th>Team</th>
                <th>Username</th>
                <th>Designation</th>
                <th>Experience</th>
                <th>Education</th>
                <th>Date Of Birth</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Other Phone</th>
                <th>Home Address</th>
                <th>Correnspondence Address</th>
                <th>Joining Date</th>
                <th>Cost To Company (CTC)</th>
                <th>Status</th>
                <th>Added On</th>
              </tr>
            </thead>
            <tbody>
              {displayteams}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
      </div>
    )
  }
}
