import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js';

export function BidderFeedback() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To get projects info, from database*/}
  const[feedbacks,setFeedbacks] = useState([]);
  useEffect(() => {
      getFeedbacks();
  }, [loggedIn, designation, navigate]);
  function getFeedbacks() {
        axios.get('/feedback.php/bidderfeedback').then(function(response){
        //console.log(response.data);
        const newArray = response.data.map(obj => {
          const employee_id = obj.employee_id;
          const TotalBids = obj.TotalBids;
          const SuccessfullBids = obj.SuccessfullBids;
          const SuccessPercentage = (parseFloat(obj.SuccessfullBids) / parseFloat(obj.TotalBids))*100;
          const BidPointsSpent = parseFloat(obj.BidPointsSpent);
          const AverageAmountPerBid = parseFloat(obj.BidPointsSpent) / parseFloat(obj.TotalBids);
          return { employee_id, TotalBids, SuccessfullBids, SuccessPercentage, BidPointsSpent, AverageAmountPerBid }
        })
        //console.log(newArray)
        setFeedbacks(newArray);
    });
  }

  // eslint-disable-next-line
  {/*To display employee info, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  if(designation === 'project_manager' || designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Bidder Feedback
        </h1>
        <div className="right"> <b>Welcome {username}</b> </div>
          <br />
          <div className="right"> <b>{designation}</b> </div>
          <br />
          <div className="right"> <b>Employee Id:</b> {employee_id} </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Bidder EID</th>
                <th>Status</th>
                <th>Bidder Name</th>
                <th>Total Bids</th>
                <th>Successfull Bids</th>
                <th>Success %</th>
                <th>Bid Points Spent</th>
                <th>Average Points Spent Per Bid<br />(Below 3.3 is good)</th>
              </tr>
            </thead>
            <tbody>
            {feedbacks
              .map((feedback, key) =>
                <tr key={key}>
                  {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
                    <td>{feedback.employee_id}</td>
                    <td>
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <div key={key}>{user.status}</div>
                    )}
                    </td>
                    <td>
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <div key={key}>{user.username}</div>
                    )}
                    </td>
                    <td>{feedback.TotalBids}</td>
                    <td>{feedback.SuccessfullBids}</td>
                    <td>{feedback.SuccessPercentage}</td>
                    <td>{feedback.BidPointsSpent}</td>
                    <td>{feedback.AverageAmountPerBid}</td>
                </tr>
              )}
            </tbody>
          </table>
      </div>
    )
  }
}
