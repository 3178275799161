import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from '../../api/axios.js'
import HRMenubar from "./../../menubars/HRMenubar"

export function UpdateUser() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const {id} = useParams();
    var designation = sessionStorage.getItem("designation")

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUser() {
        axios.get(`/user/${id}`).then(function(response) {
            //console.log(response.data);
            setInputs(response.data);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`/user/${id}/edit`, inputs).then(function(response){
            //console.log(response.data);
            navigate('/users/listusers/');
        });
    }

    var editable_by_admin = false
    if(designation === "admin"){
      editable_by_admin = true;
    }

    var editable_by_project_manager = false
    if(designation === "project_manager"){
      editable_by_project_manager = true;
    }

    var editable_by_hr = false
    if(designation === "human_resource"){
      editable_by_hr = true;
    }

    if(designation === 'project_manager' || designation === 'admin' || designation === 'human_resource'){
      return (
          <div className="pagePadding">
            <div className="left">
              <HRMenubar />
            </div>
            <h1>Edit User</h1>
            <div>
              <form onSubmit={handleSubmit}>
                  <table cellSpacing="10">
                      <tbody>
                        {editable_by_hr ? ( <>
                           <tr>
                                <th>
                                    <label>Name*: </label>
                                </th>
                                <td>
                                    <input value={inputs.username} type="text" name="username" onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                 <th>
                                     <label>Employee ID*: </label>
                                 </th>
                                 <td>
                                     <input value={inputs.employee_id} type="text" name="employee_id" onChange={handleChange} />
                                 </td>
                             </tr>
                             <tr>
                                 <th>
                                     <label>Designation: </label>
                                 </th>
                                 <td>
                                   <select value={inputs.designation} name="designation" onChange={handleChange} defaultValue={'DEFAULT'} >
                                     <option value="DEFAULT" disabled>Choose a Designation.</option>
                                     <option value="admin">Admin</option>
                                     <option value="project_manager">Project Manager</option>
                                     <option value="human_resource">Human Resource</option>
                                     <option value="team_leader">Team Leader</option>
                                     <option value="software_developer">Software Developer</option>
                                     <option value="software_designer">Software Designer</option>
                                     <option value="bidder">Bidder</option>
                                     <option value="maintenance_worker">Maintenance Worker</option>
                                   </select>
                                 </td>
                             </tr>
                            <tr>
                                 <th>
                                     <label>Team Allocated: </label>
                                 </th>
                                 <td>
                                 <select name="team_allocated" onChange={handleChange} value={inputs.team_allocated}>
                                   <option value="DEFAULT" disabled>Choose a Team.</option>
                                   <option value="alpha">Alpha</option>
                                   <option value="beta">Beta</option>
                                   <option value="gamma">Gamma</option>
                                   <option value="delta">Delta</option>
                                   <option value="bidder">Bidder</option>
                                   <option value="team_leader">Team Leader</option>
                                   <option value="human_resource">Human Resource</option>
                                   <option value="maintenance">Maintenance Worker</option>
                                   <option value="security">Security Guard</option>
                                   <option value="admin">Admin</option>
                                   <option value="project_manager">Project Manager</option>
                                 </select>
                                 </td>
                             </tr>
                             <tr>
                                  <th>
                                      <label>Skills*: </label>
                                  </th>
                                  <td>
                                      <textarea value={inputs.skills} name="skills" onChange={handleChange} />
                                  </td>
                              </tr>
                              <tr>
                                   <th>
                                       <label>Experience*: </label>
                                   </th>
                                   <td>
                                       <textarea value={inputs.experience} name="experience" onChange={handleChange} />
                                   </td>
                               </tr>
                               <tr>
                                    <th>
                                        <label>Education*: </label>
                                    </th>
                                    <td>
                                        <textarea value={inputs.education} name="education" onChange={handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                     <th>
                                         <label>List Documents Submitted*: </label>
                                     </th>
                                     <td>
                                         <textarea value={inputs.documents} name="documents" onChange={handleChange} />
                                     </td>
                                 </tr>
                                 <tr>
                                      <th>
                                          <label>Email*: </label>
                                      </th>
                                      <td>
                                          <input value={inputs.email} type="text" name="email" onChange={handleChange} />
                                      </td>
                                  </tr>
                                  <tr>
                                       <th>
                                           <label>Mobile*: </label>
                                       </th>
                                       <td>
                                           <input value={inputs.mobile} type="text" name="mobile" onChange={handleChange} />
                                       </td>
                                   </tr>
                                   <tr>
                                        <th>
                                            <label>Other Phone: </label>
                                        </th>
                                        <td>
                                            <input value={inputs.other_phone} type="text" name="other_phone" onChange={handleChange} />
                                        </td>
                                    </tr>
                            <tr>
                                <th>
                                    <label>Date Of Birth*: </label>
                                </th>
                                <td>
                                    <input value={inputs.date_of_birth} type="date" name="date_of_birth" onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                 <th>
                                     <label>Home Address*: </label>
                                 </th>
                                 <td>
                                     <textarea value={inputs.home_address} name="home_address" onChange={handleChange} />
                                 </td>
                             </tr>
                             <tr>
                                  <th>
                                      <label>Correspondene Address: </label>
                                  </th>
                                  <td>
                                      <textarea value={inputs.correspondence_address} name="correspondence_address" onChange={handleChange} />
                                  </td>
                              </tr>
                              <tr>
                                  <th>
                                      <label>Joining Date*: </label>
                                  </th>
                                  <td>
                                      <input type="date" name="joining_date" onChange={handleChange} />
                                  </td>
                              </tr>
                            <tr>
                                <th>
                                    <label>Company Left Date <br />with reason: </label>
                                </th>
                                <td>
                                    <textarea value={inputs.left_date} name="left_date" onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Status: </label>
                                </th>
                                <td>
                                <select value={inputs.status} name="status" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                                  <option value="DEFAULT" disabled>Choose a Type.</option>
                                  <option value="not_joined_yet">Not Joined Yet</option>
                                  <option value="active">Active</option>
                                  <option value="left">Left</option>
                                </select>
                                </td>
                            </tr>
                            <tr>
                                 <th>
                                     <label>Password*: </label>
                                 </th>
                                 <td>
                                     <input value={inputs.password} type="text" name="password" onChange={handleChange} />
                                 </td>
                             </tr>
                             <tr>
                                  <th>
                                      <label>HR Interview Score<br />(Dress, Cleanliness, <br />Behavior & Communication) <br />(Out of 10): </label>
                                  </th>
                                  <td>
                                      <input value={inputs.hr_interview_score} type="text" name="hr_interview_score" onChange={handleChange} />
                                  </td>
                              </tr>
                             <tr>
                                  <th>
                                      <label>Technical Interview Score <br />(Out of 10): </label>
                                  </th>
                                  <td>
                                      <input value={inputs.technical_interview_score} type="text" name="technical_interview_score" onChange={handleChange} />
                                  </td>
                              </tr>
                           </> ) : ("")}
                           {editable_by_admin ? ( <>
                             <tr>
                                  <th>
                                      <label>CTC Approval By Admin <br />(Check Salary Discussed, <br />Overal Company Budget): </label>
                                  </th>
                                  <td>
                                      <input value={inputs.ctc_approved_by_admin} type="number" name="ctc_approved_by_admin" onChange={handleChange} />
                                  </td>
                              </tr>
                            </> ) : ("")}
                           {editable_by_admin ? ( <>
                             <tr>
                                  <th>
                                      <label>Final Approval By Admin<br />Verify Documents): </label>
                                  </th>
                                  <td>
                                    <select value={inputs.joining_approval_by_admin} name="joining_approval_by_admin" onChange={handleChange} defaultValue={'DEFAULT'} >
                                      <option value="DEFAULT" disabled>Choose a Type.</option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                      <option value="documents_not_verified_yet">Documents Not Verified Yet</option>
                                    </select>
                                  </td>
                              </tr>
                            </> ) : ("")}
                            {editable_by_project_manager ? ( <>
                              <tr>
                                   <th>
                                       <label>Joining Approval By Project Manager <br />on Basis of Resume, <br />Experience, Qualification, <br />Scores and <br />Company Requirement: </label>
                                   </th>
                                   <td>
                                       <select value={inputs.joining_approval_by_project_manager} name="joining_approval_by_project_manager" onChange={handleChange} defaultValue={'DEFAULT'} >
                                         <option value="DEFAULT" disabled>Choose a Type.</option>
                                         <option value="yes">Yes</option>
                                         <option value="no">No</option>
                                       </select>
                                   </td>
                               </tr>
                            </> ) : ("")}
                          <tr>
                              <td colSpan="2" align ="right">
                                  <button>Save</button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </form>
            </div>
          </div>
      )
    }
}
