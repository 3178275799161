import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function CommonInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  return (
    <>
      <div className="pad1px commonInfoBar">
      <h2>Common Info Bar (Read daily)</h2>
      <ul>
        <li>Always do 'Daily Activities Entry' at end of the day.</li>
        <li>Daily check 'Attendance Info'. No corrections allowed after one working day.</li>
        <li>Never save passwords in browser.Access of Management System outside company or on Phone is not allowed.</li>
        <li>Open 'Company Management System' only in one tab in browser.</li>
      </ul>
      </div>
    </>
  )

}

export default CommonInfobar;
