import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListAttendance() {
  const[attendance,setAttendance] = useState([]);
  const[attendance_keys,setAttendance_keys] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getAttendance();
    }
  }, [loggedIn, navigate]);

  function getAttendance() {
    axios.get('/attendance.php').then(function(response){
      //console.log(response.data);
      setAttendance(response.data);

      const keys_array = response.data.map((o) => {
        return Object.keys(o)
      }).reduce((prev, curr) => {
        return prev.concat(curr)
      }).filter((col, i, array) => {
        return array.indexOf(col) === i
      });
      setAttendance_keys(keys_array);

    })
  }

  const[pageNumber, setPageNumber] = useState(0)
  const attendancePerPage = 30
  const pageVisited = pageNumber * attendancePerPage
  const pageCount = Math.ceil(attendance.length / attendancePerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayAttendance = attendance
  .slice(pageVisited, pageVisited + attendancePerPage)
  .map((list, key) =>
    <tr key={key} className="attendance_tr_td_max_char">

      {attendance_keys
        .map((arr) => (
          <td className="attendance_tr_td_max_char">{list[arr]}</td>
        )
      )}

    </tr>
  )

  if(designation === 'assistant' || designation === 'human_resource'){
    return (
      <div className="pagePadding">
        <h1>List Attendance</h1>
        <div>
          <table className="attendance_table_max_char">
            <thead>
              <tr>
                {attendance_keys
                  .map((arr) => ( <th>{arr}</th> )
                )}
              </tr>
            </thead>
            <tbody>
              {displayAttendance}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
          <br />
          <center><b>Important:</b> Each row may have 9 to 12 entries, based on different attendance characters.</center>
        </div>
      </div>
    )
  }
}
