import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function MaintenanceInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'maintenance_worker') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> Maintenance Important Duties (Read daily):
        <li>Update Work problem, available solutions, solution done.</li>
        <li>Communicate HR with details if need of repair funds.</li>
        <li>Aim for quick solutions and without additional funds.</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>To delete any entry, request Admin by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
      </div>
    </>
  )
}

export default MaintenanceInfobar;
