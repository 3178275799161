import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function AssistantMenubar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'admin') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>
              <Link to="/">Dashboard</Link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to="/users/listusers">Employees</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/viewteams">View Teams</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/listprojects">View Projects</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/listmaintenanceactivity">Maintenance Activities</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/listbidderactivity">Bidder Activity</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/listdailyactivities">All Daily Activity Entries</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/maintenanceworkerfeedback">Maintenance Worker Feedback</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/employeefeedback">Employee Feedback</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/teamfeedback">Team Leader Feedback</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/bidderfeedback">Bidder Feedback</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/users/listattendance">List Attendance</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/users/deleteattendance">Delete Attendance</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/viewallmessages">View All Messages</Link>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )

}

export default AssistantMenubar;
