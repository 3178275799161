import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

function EmployeeMessages() {
  const[messages,setMessages] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getMessages();
    }
    // eslint-disable-next-line
  }, [loggedIn, navigate]);

  function getMessages() {
        axios.get('/messages.php').then(function(response){
        //console.log(response.data);
        //setMessages(response.data);

        // eslint-disable-next-line
        const newArray = response.data.map(obj => {
          if(obj.sent_from === employee_id || obj.sent_to === employee_id){
            const id = obj.id;
            const cc = obj.cc;
            const message_body = obj.message_body;
            const message_type = obj.message_type;
            const sent_from = obj.sent_from;
            const sent_to = obj.sent_to;
            const subject = obj.subject;
            const created_at = obj.created_at;

            return { id, cc, message_body, message_type, sent_from, sent_to, subject, created_at }
          }
        })
        //console.log(newArray)
        setMessages(newArray);
    });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    })
  }

  const[pageNumber, setPageNumber] = useState(0)
  const messagesPerPage = 5
  const pageVisited = pageNumber * messagesPerPage
  const pageCount = Math.ceil(messages.filter(n => n).length / messagesPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayMessages = messages
  .filter(message => message)
  .slice(pageVisited, pageVisited + messagesPerPage)
  .map((message, key) =>
    <tr key={key}>
      {message.sent_from === employee_id || message.sent_to === employee_id ? ( <>
        <td>{message.id}</td>
        <td>{message.message_type}</td>
        {users
          .filter(user => user.employee_id === message.sent_from)
          .map((user, key) =>
          <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
        )}
        {users
          .filter(user => user.employee_id === message.sent_to)
          .map((user, key) =>
          <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
        )}
        <td>{message.cc}</td>
        <td>{message.subject}</td>
        <td>{message.message_body}</td>
        <td>{message.created_at}</td>
      </> ) : ("")}
    </tr>
  )

  return (
    <div className="pagePadding">
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Message Type</th>
              <th>From</th>
              <th>To</th>
              <th>CC</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Sent Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {displayMessages}
          </tbody>
        </table>
        <ReactPaginate
        	previousLabel={"Previous"}
        	nextLabel={"Next"}
        	pageCount={pageCount}
        	onPageChange={changePage}
        	containerClassName={"paginationButtons"}
        	previousLinkClassName={"privousButton"}
        	nextLinkClassName={"nextButton"}
        	disabledClassName={"paginationDisabled"}
        	activeClassName={"paginationActive"}
        />
      </div>
    </div>
  )
}

export default EmployeeMessages;
