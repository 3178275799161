import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function AdminInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'admin') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> Admin Important Duties (Read daily):
        <li>Manage all charges, salary details, maintenance charges, bidder weekly charged points, etc.</li>
        <li>Regularly review if correct data updated by HR, Maintenance workers and if doing correct solutions.</li>
        <li>Always double check (by self visits to see broken things) where charges asked are mandatory and if fully used, on basis of receipts.</li>
        <li>Approve amounts requested by HR and transfer to payments account, where they can withdraw through HR.</li>
        <li>Check how to lower charges to keep things running.</li>
        <li>Check if HR updating data(daily attendance, new employee documents, payments, important information forwarding to workers), social activities, etc. Can warn HR if not/wrong performing.</li>
        <li>Remind HR for festival decoration.</li>
        <li>Verify new employee documents.</li>
        <li>Can warn employees and hire/fire after communication with Project Manager, through HR.</li>
        <li>Not allowed to hold any amount on company's behalf for more than a week and verify no other employee do it.</li>
        <li>Work on any required company documentation work.</li>
        <li>Make reports of approved charges, fines taken, with screenshots and explanation, with dates of amount put/withdrawn from bank. Documentation work done.</li>
        <li>Daily check projects done by Maintenance Workers, 'Lock' them, if completed.</li>
        <li>Don't give Maintenance Worker Feedback on minor tasks where extra charges not included.</li>
        <li>When new joining, approve/disapprove candidate on basis of available documents and scores, on basis of requirement. Update it in online</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>Read Daily Activity Entry of Employees, visible on dashboard</li>
        <li>To delete any entry, request 'Assistant' by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
        <li>Only mode of communication allowed in company is through <Link to="/contact">message</Link>, to get any work done, so that each activity get noted.</li>
        <li>If anyone leave company immediately, their salary won't be paid. Deposit it back in company bank. Check daily attendance of workers.</li>
        <li>If anyone absent for three days regularly. They will be marked as 'left company'. If an employee want to join back, they will need to give interview and new joining(employee_id) and can join after approval of Admin and Project Manager. You can select them cautiously, only if company needs them.</li>
        <li>Verify with HR if any fines taken must be submitted in company bank within three working days.</li>
        <li>Any fine taken must be mentioned in daily entry, informed to <Link to="/contact">Assistant</Link> and submitted to company bank within three working days.</li>
        <li>Bonus is not allowed, but only adjustment of any absent from unpaid salary. Contact <Link to="/contact">Assistant</Link> to mark a present for employee, with employee Id and reason.</li>
      </div>
    </>
  )

  }

  export default AdminInfobar;
