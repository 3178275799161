import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AllMessages from "./notification_management/views/EmployeeMessages"
import ReceivedMessages from "./notification_management/views/ReceivedMessages"
import SentMessages from "./notification_management/views/SentMessages"
import Notifications from "./notification_management/views/Notifications"

export function Messages() {

  var loggedIn = sessionStorage.getItem("loggedIn")
  //var designation = sessionStorage.getItem("designation")
  var navigate = useNavigate();

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  return (
    <>
      <h1>Messages</h1>
      <br />
      <div><AllMessages /></div><div><ReceivedMessages /></div><div><SentMessages /></div><div><Notifications /></div>
    </>
  )
}
