import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListBidderActivity() {
  const[bidderactivity,setbidderactivity] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getbidderactivity();
    }
  }, [loggedIn, navigate]);

  function getbidderactivity() {
        axios.get('/bidder_activity.php').then(function(response){
        //console.log(response.data);
        setbidderactivity(response.data);
    });
  }

  var editable_by_assistant = false
  if(designation === "assistant"){
    editable_by_assistant = true;
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const deleteActivity = (id) => {
          axios.delete(`/bidder_activity.php/${id}/delete`).then(function(response){
          //console.log(response.data);
          getbidderactivity();
      });
  }

  const[pageNumber, setPageNumber] = useState(0)
  const activitiesPerPage = 5
  const pageVisited = pageNumber * activitiesPerPage
  const pageCount = Math.ceil(bidderactivity.length / activitiesPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayBidderActivity = bidderactivity
    .map((activity, key) =>
    <tr key={key}>
      <td>{activity.id}</td>
      <td>
      {users
        .filter(user => user.employee_id === activity.employee_id)
        .slice(pageVisited, pageVisited + activitiesPerPage)
        .map((user, key) =>
        <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
      )}
      </td>
      <td>{activity.name_client_communicated}</td>
      <td>{activity.new_recurring_client}</td>
      <td>{activity.total_bid_points}</td>
      <td>{activity.hire_successfull}</td>
      <td>{activity.project_bid_name}</td>
      <td>{activity.source_platform}</td>
      <td>
      {users
        .filter(user => user.employee_id === activity.assigned_to_tl)
        .map((user, key) =>
        <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
      )}
      </td>
      <td>{activity.comments}</td>
      <td>{activity.created_at}</td>
      {editable_by_assistant ? ( <>
        <td>
          <button onClick={() => deleteActivity(activity.id)}>Delete</button>
        </td>
      </> ) : ("")}
    </tr>
  )

  if(designation === 'bidder' || designation === 'project_manager'|| designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Bidder Activity
        </h1>
        <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Bidder</th>
                <th>Client Name</th>
                <th>New/Recurring Client</th>
                <th>Bid Points Used</th>
                <th>Hire Status</th>
                <th>Project Name</th>
                <th>Source Platform</th>
                <th>Project Assigned To</th>
                <th>Bidder Comments</th>
                <th>Created On</th>
                {editable_by_assistant ? ( <>
                  <th>Delete Activity</th>
                </> ) : ("")}
              </tr>
            </thead>
            <tbody>
              {displayBidderActivity}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
        </div>
      </div>
    )
  }
}
