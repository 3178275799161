import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'

function CreateMessage() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const time = current.getHours()
        + ':' + current.getMinutes()
        + ":" + current.getSeconds();
  const date_time = date+'/'+time;

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));

  }

  const handleSubmit = (event) => {
    event.preventDefault();

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    var value3 = document.getElementById("dropdown_id3");
    var getvalue3 = value3.options[value3.selectedIndex].value;

    var value4 = document.getElementById("dropdown_id4");
    var getvalue4 = value4.options[value4.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT" || getvalue3 === "DEFAULT" || getvalue4 === "DEFAULT"){
      alert("Fill required fields.")
    }
    else {
      axios.post('/messages.php', inputs).then(function(response){
        //console.log(response.data);
        alert(response.data.message)
        navigate('/'); //After saving, redirect to list of users
      });
    }
  }

  return (
    <>
      <div>
        <div>
          <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
              <tbody>
                  <tr>
                      <th>
                          <label>Message Type*: </label>
                      </th>
                      <td>
                        <select name="message_type" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id1" >
                          <option value="DEFAULT" disabled>Select</option>
                          <option value="message">Message</option>
                        </select>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>From*: </label>
                      </th>
                      <td>
                        <select name="sent_from" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id2" >
                          <option value="DEFAULT" disabled>Select</option>
                          <option value={employee_id}>{employee_id}</option>
                        </select>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>To*: </label>
                      </th>
                      <td>
                        <select name="sent_to" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id3" >
                          <option value="DEFAULT" disabled>Select</option>
                          {users
                            .map((user, key) =>
                            <option key={key} value={user.employee_id}>{user.username} ({user.employee_id}) {user.designation}</option>
                          )}
                        </select>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>CC (Enter employee Ids, comma separated)*: </label>
                      </th>
                      <td>
                        <input type="text" name="cc" onChange={handleChange} size="75" />
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>Subject*: </label>
                      </th>
                      <td>
                        <input type="text" name="subject" onChange={handleChange} required />
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>Message (Max 150 words)*: </label>
                      </th>
                      <td>
                        <textarea name="message_body" rows="10" cols="70" maxLength="150" onChange={handleChange} required />
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <label>Select Date/Time*: </label>
                      </th>
                      <td>
                        <select name="created_at" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id4" >
                          <option value="DEFAULT" disabled>Select</option>
                          <option value={date_time}>{date_time}</option>
                        </select>
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="2" align ="right">
                          <button>Save</button>
                      </td>
                  </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </>
  )
}

export default CreateMessage;
