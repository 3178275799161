import { NavLink, Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { ProjectsInfo } from './pages/ProjectsInfo'
import { Contact } from './pages/Contact'
import { CreateUser } from './pages/employee_management/CreateUser'
import { ListUsers } from './pages/employee_management/ListUsers'
import { UpdateUser } from './pages/employee_management/UpdateUser'
import { ViewTeams } from './pages/employee_management/views/ViewTeams'
import Login from './pages/users/Login'
import { Logout } from './pages/users/Logout'
import { CreateProject } from './pages/project_management/PMTeamProjects/CreateProject'
import { ListProjects } from './pages/project_management/PMTeamProjects/ListProjects'
import { UpdateProject } from './pages/project_management/PMTeamProjects/UpdateProject'
import { CreateBidderActivity } from './pages/project_management/PMTeamProjects/BidderActivity/CreateBidderActivity'
import { ListBidderActivity } from './pages/project_management/PMTeamProjects/BidderActivity/ListBidderActivity'
import { EmployeeFeedback } from './pages/project_management/PMTeamProjects/views/EmployeeFeedback'
import { TeamFeedback } from './pages/project_management/PMTeamProjects/views/TeamFeedback'
import { BidderFeedback } from './pages/project_management/PMTeamProjects/views/BidderFeedback'
import { CreateMaintenanceActivity } from './pages/project_management/AdminTeamProjects/CreateMaintenanceActivity'
import { ListMaintenanceActivity } from './pages/project_management/AdminTeamProjects/ListMaintenanceActivity'
import { UpdateMaintenanceActivity } from './pages/project_management/AdminTeamProjects/UpdateMaintenanceActivity'
import { MaintenanceWorkerFeedback } from './pages/project_management/AdminTeamProjects/views/MaintenanceWorkerFeedback'
import { DailyActivities } from './pages/employee_management/daily_activity_entry/DailyActivities'
import { ListDailyActivities } from './pages/employee_management/daily_activity_entry/ListDailyActivities'
import { CreateAttendance } from './pages/attendance_management/CreateAttendance'
import { ListAttendance } from './pages/attendance_management/ListAttendance'
import { DeleteAttendance } from './pages/attendance_management/DeleteAttendance'
import { AttendanceInfo } from './pages/attendance_management/views/AttendanceInfo'
import { SalaryCalculation } from './pages/charges_management/views/SalaryCalculation'
import { ViewAllMessages } from './pages/notification_management/views/ViewAllMessages'
import { Messages } from './pages/Messages'

import {NotFound} from './pages/NotFound'
import logo from './menubars/ziscom_logo.jpg'
import './menubars/navbar.css';
import './App.css';

function App() {

  var designation = sessionStorage.getItem("designation")
  var not_for_employee = true
  if(designation === "software_developer" || designation === "software_designer" || designation === "maintenance_worker" || designation === "human_resource" || designation === "assistant"){
    not_for_employee = false;
  }

  return (
    <>
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <nav>
          <ul>
            <li><NavLink to="/">Dashboard</NavLink></li>
            <li><NavLink to="/dailyactivities">Daily Activities Entry</NavLink></li>
            {not_for_employee ? ( <>
              <li><NavLink to="/projectsinfo">Projects Info</NavLink></li>
            </> ) : ("")}
            <li><NavLink to="/salaryinfo">Salary Info</NavLink></li>
            <li><NavLink to="/attendanceinfo">Attendance Info</NavLink></li>
            <li><NavLink to="/messages">Messages</NavLink></li> {/*Create navigation bar button for '/books' url*/}
            <li><NavLink to="/Contact">Contact</NavLink></li>
            <li><NavLink end to="/logout">Logout</NavLink></li>

            {/*end in above line means don't put active color in sub menus*/}
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact/" element={<Contact />} /> {/*Ad /* in end of url here, so that anything after url, gets added*/}
          <Route path="/users/createuser/" element={<CreateUser />} />
          <Route path="/users/listusers" element={<ListUsers />} />
          <Route path="/users/listusers/user/:id/edit" element={<UpdateUser />} />
          <Route path="/viewteams/" element={<ViewTeams />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/createproject/" element={<CreateProject />} />
          <Route path="/listprojects/" element={<ListProjects />} />
          <Route path="/listprojects/project/:id/edit" element={<UpdateProject />} />
          <Route path="/projectsinfo/" element={<ProjectsInfo />} />
          <Route path="/employeefeedback/" element={<EmployeeFeedback />} />
          <Route path="/teamfeedback/" element={<TeamFeedback />} />
          <Route path="/createbidderactivity/" element={<CreateBidderActivity />} />
          <Route path="/listbidderactivity/" element={<ListBidderActivity />} />
          <Route path="/bidderfeedback/" element={<BidderFeedback />} />
          <Route path="/createmaintenanceactivity/" element={<CreateMaintenanceActivity />} />
          <Route path="/listmaintenanceactivity/" element={<ListMaintenanceActivity />} />
          <Route path="/listmaintenanceactivity/:id/edit" element={<UpdateMaintenanceActivity />} />
          <Route path="/maintenanceworkerfeedback/" element={<MaintenanceWorkerFeedback />} />
          <Route path="/dailyactivities/" element={<DailyActivities />} />
          <Route path="/listdailyactivities/" element={<ListDailyActivities />} />
          <Route path="/users/createattendance/" element={<CreateAttendance />} />
          <Route path="/users/listattendance/" element={<ListAttendance />} />
          <Route path="/users/deleteattendance/" element={<DeleteAttendance />} />
          <Route path="/attendanceinfo/" element={<AttendanceInfo />} />
          <Route path="/salaryinfo/" element={<SalaryCalculation />} />
          <Route path="/viewallmessages/" element={<ViewAllMessages />} />
          <Route path="/messages/" element={<Messages />} />

          <Route path="*" element={<NotFound />} /> {/*If a url don't exist, it will send to 'Not Found' page*/}
        </Routes>
      </header>
    </div>
    </>
  )
}

export default App;
