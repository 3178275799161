import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js';

export function MaintenanceWorkerFeedback() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To get projects info, from database*/}
  const[feedbacks,setFeedbacks] = useState([]);
  useEffect(() => {
      getFeedbacks();
  }, [loggedIn, designation, navigate]);
  function getFeedbacks() {
        axios.get('/feedback.php/maintenanceworkerfeedback').then(function(response){
        //console.log(response.data);
        const newArray = response.data.map(obj => {
          const employee_id = obj.employee_id;
          const InitialAmountApproved = obj.ApprovedAmount;
          const FinalAmountSpent = obj.ActualSpent;
          const AmountSaver = parseFloat(obj.ApprovedAmount) - parseFloat(obj.ActualSpent)
          const WorkQualityScore = (parseFloat(obj.WorkQualityScore)/(parseFloat(obj.TotalProjectsWithFeedback) * 10)) * 100
          const CompleteInTimeScore = (parseFloat(obj.CompleteInTimeScore)/(parseFloat(obj.TotalProjectsWithFeedback) * 10)) * 100
          const WorkerEstimates = (parseFloat(obj.WorkerEstimates)/(parseFloat(obj.TotalProjectsWithFeedback) * 10)) * 100
          const LeastChargeScore = (parseFloat(obj.LeastChargeScore)/(parseFloat(obj.TotalProjectsWithFeedback) * 10)) * 100
          const TotalProjects = obj.TotalProjects
          const TotalProjectsWithoutFeedback = parseFloat(obj.TotalProjects) - parseFloat(obj.TotalProjectsWithFeedback)
          const TotalProjectsWithFeedback = parseFloat(obj.TotalProjectsWithFeedback)
          return { employee_id, TotalProjects, TotalProjectsWithoutFeedback, TotalProjectsWithFeedback, InitialAmountApproved, FinalAmountSpent, AmountSaver, WorkQualityScore, CompleteInTimeScore, WorkerEstimates, LeastChargeScore, FinalScoreByAdmin: (parseFloat(WorkQualityScore+CompleteInTimeScore+WorkerEstimates+LeastChargeScore)/4)}
        })
        //console.log(newArray)
        setFeedbacks(newArray);
    });
  }

  // eslint-disable-next-line
  {/*To display employee info, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  if(designation === 'admin' || designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Maintenance Worker Feedback
        </h1>
        <div className="right"> <b>Welcome {username}</b> </div>
          <br />
          <div className="right"> <b>{designation}</b> </div>
          <br />
          <div className="right"> <b>Employee Id:</b> {employee_id} </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Status</th>
                <th>Employee Name</th>
                <th>Designation</th>
                <th>Team</th>
                <th>Total Projects With Feedback</th>
                <th>Initial Approved Amount</th>
                <th>Final Amount Spent</th>
                <th>Amount Saved (+ is good)</th>
                <th>Work Quality %</th>
                <th>Complete In Time %</th>
                <th>Correct Solution/Amount Estimates %</th>
                <th>Least Charging %</th>
                <th>Final Scores (% Calculated by System)</th>
                <th>Feedback not counted for projects</th>
              </tr>
            </thead>
            <tbody>
            {feedbacks
              .map((feedback, key) =>
                <tr key={key}>
                  {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
                    <td>{feedback.employee_id}</td>
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <td key={key}>{user.status}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <td key={key}>{user.username}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <td key={key}>{user.designation}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.employee_id)
                      .map((user, key) =>
                      <td key={key}>{user.team_allocated}</td>
                    )}
                    <td>{feedback.TotalProjectsWithFeedback}</td>
                    <td>{feedback.InitialAmountApproved}</td>
                    <td>{feedback.FinalAmountSpent}</td>
                    <td>{feedback.AmountSaver}</td>
                    <td>{feedback.WorkQualityScore}</td>
                    <td>{feedback.CompleteInTimeScore}</td>
                    <td>{feedback.WorkerEstimates}</td>
                    <td>{feedback.LeastChargeScore}</td>
                    <td>{feedback.FinalScoreByAdmin}</td>
                    <td>{feedback.TotalProjectsWithoutFeedback}</td>
                </tr>
              )}
            </tbody>
          </table>
      </div>
    )
  }
}
