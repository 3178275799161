import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function MaintenanceWorkerMenubar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'maintenance_worker' && designation !== 'admin') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>
              <Link to="/">Dashboard</Link>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to="/createmaintenanceactivity">Create Maintenance Activity</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/listmaintenanceactivity">Maintenance Activities</Link>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )

}

export default MaintenanceWorkerMenubar;
