import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import BidderMenubar from "./../../../menubars/BidderMenubar"
import TLMenubar from "./../../../menubars/TLMenubar"

export function CreateProject() {
  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var username = sessionStorage.getItem("username")
  var employee_id = sessionStorage.getItem("employee_id")
  var logged_in_user_info = username+' ('+employee_id+') '+designation

  var editable = false
  if(designation === "team_leader"){
    editable = true;
  }

  var not_for_bidder = true
  if(designation === "bidder"){
    not_for_bidder = false;
  }

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const time = current.getHours()
        + ':' + current.getMinutes()
        + ":" + current.getSeconds();
  const date_time = date+' '+time;

  useEffect(() => {
    if(loggedIn === null && (designation !== 'team_leader' || designation !== 'bidder')){
      navigate('/login');
    }
    else if(designation !== 'bidder'){
      //navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));

  }

  const handleSubmit = (event) => {
    event.preventDefault();

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    var value3 = document.getElementById("dropdown_id3");
    var getvalue3 = value3.options[value3.selectedIndex].value;

    var value4 = document.getElementById("dropdown_id4");
    var getvalue4 = value4.options[value4.selectedIndex].value;

    var value5 = document.getElementById("dropdown_id5");
    var getvalue5 = value5.options[value5.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT" || getvalue3 === "DEFAULT" || getvalue4 === "DEFAULT" || getvalue5 === "DEFAULT"){
      alert("Fill required fields.")
    }
    else {
      axios.post('/projects.php', inputs).then(function(response){
        //console.log(response.data);
        if(designation === 'bidder'){
          navigate('/projectsinfo');
        }else {
          navigate('/listprojects/'); //After saving, redirect to list of users
        }
      });
    }
  }

  if(designation === 'team_leader' || designation === 'project_manager' || designation === 'bidder'){
    return (
      <div className="pagePadding">
        <div className="left">
        {editable ? ( <> <TLMenubar /> </> ) : ( <> <BidderMenubar /> </> )}
        </div>
        <h1>Create Project</h1>
        <div>
          <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
              <tbody>
                  <tr>
                      <th>
                          <label>Project Name*: </label>
                      </th>
                      <td>
                          <input type="text" name="project_name" onChange={handleChange} required />
                      </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Client Name*: </label>
                     </th>
                     <td>
                         <input type="text" name="client_name" onChange={handleChange} required />
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Project Description (Mention Technology, other details by client)*: </label>
                     </th>
                     <td>
                         <textarea name="project_details" onChange={handleChange} required />
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Hourly/Fixed Price*: </label>
                     </th>
                     <td>
                         <select name="hourly_fixed_price" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                           <option value="DEFAULT" disabled>Choose a Type.</option>
                           <option value="hourly">Hourly</option>
                           <option value="fixed_price">Fixed Price</option>
                         </select>
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Amount*: </label>
                     </th>
                     <td>
                         <input type="text" name="amount" onChange={handleChange} required />
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>NOC required*: </label>
                     </th>
                     <td>
                         <select name="noc_required" id="dropdown_id2" onChange={handleChange} defaultValue={'DEFAULT'} >
                           <option value="DEFAULT" disabled>Choose</option>
                           <option value="yes">Yes</option>
                           <option value="no">No</option>
                         </select>
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Project Bid By*: </label>
                     </th>
                     <td>
                         <select name="project_bid_by" id="dropdown_id3" onChange={handleChange} defaultValue={'DEFAULT'} >
                           <option value="DEFAULT" disabled>Select</option>
                           {users
                             .filter((user) => user.designation ==='bidder')
                             .map((user, key) =>
                             <option key={key} value={user.employee_id}>{user.username} ({user.employee_id}) {user.designation}</option>
                           )}
                           <option value={employee_id}>{logged_in_user_info}</option>
                         </select>
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Fresh client or Returning client*: </label>
                     </th>
                     <td>
                         <select name="fresh_client_or_recurring_client" id="dropdown_id4" onChange={handleChange} defaultValue={'DEFAULT'} >
                           <option value="DEFAULT" disabled>Client type.</option>
                           <option value="fresh">Fresh</option>
                           {not_for_bidder ? ( <>
                             <option value="returning">Returning</option>
                           </> ) : ("") }
                         </select>
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Project Source Platform*: </label>
                     </th>
                     <td>
                         <input type="text" name="project_source_platform" onChange={handleChange} required />
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Project Assign Date*: </label>
                     </th>
                     <td>
                         <input type="date" name="project_assign_date" onChange={handleChange} required />
                     </td>
                  </tr>
                  <tr>
                     <th>
                         <label>Project Assigned To Team Leader*: </label>
                     </th>
                     <td>
                         <select name="project_assigned_to_tl" id="dropdown_id5" onChange={handleChange} defaultValue={'DEFAULT'} >
                            <option value="DEFAULT" disabled>Choose Team Leader.</option>
                            {users
                              .filter(user => user.designation ==='team_leader')
                              .map((user, key) =>
                              <option key={key} value={user.employee_id}>{user.username} {user.employee_id} {user.designation}</option>
                            )}
                        </select>
                     </td>
                  </tr>
                  <tr>
                    <th>
                      <label>Created On*</label>
                    </th>
                    <td>
                      <select name="date_time" onChange={handleChange} defaultValue={'DEFAULT'} required >
                        <option value="DEFAULT" disabled>Select</option>
                        <option value={date_time}>{date_time}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                      <td colSpan="2" align ="right">
                          <button>Save</button>
                      </td>
                  </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    )
  }
}
