import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'

export function AttendanceInfo() {
  const[attendance,setAttendance] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")
  var e_id = "eid_"+employee_id

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getAttendance();
    }
    // eslint-disable-next-line
  }, [loggedIn, navigate]);

  function getAttendance() {
    axios.get("/attendance.php").then(function(response){

      const newArray = response.data.map(obj => {
        const last_attendance_date = obj.date;
        const attendance = obj[e_id];
        const count_present = (attendance.match(/P/g) || []).length;
        const count_half_day = (attendance.match(/H/g) || []).length;
        const count_full_leave = (attendance.match(/L/g) || []).length;
        const count_short_leave = (attendance.match(/S/g) || []).length;
        const count_absent = (attendance.match(/A/g) || []).length;

        return { last_attendance_date, attendance, count_present, count_half_day, count_full_leave, count_short_leave, count_absent }
      })

      setAttendance(newArray);
    })
  }

  return (
    <div className="pagePadding">
      <h1>Daily Attendance</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>Attendance Until</th>
              <th>Monthly Attendance</th>
              <th>Total Present</th>
              <th>Half Day Leave</th>
              <th>Short Leave</th>
              <th>Full Day Leave</th>
              <th>Total Absent</th>
            </tr>
          </thead>
          <tbody>
            {attendance
              .map((atten, key) =>
              <tr key={key}>
                <td>{atten.last_attendance_date}</td>
                <td>{atten.attendance}</td>
                <td>{atten.count_present}</td>
                <td>{atten.count_half_day}</td>
                <td>{atten.count_short_leave}</td>
                <td>{atten.count_full_leave}</td>
                <td>{atten.count_absent}</td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <center><b>Important:</b> There can be <i>Max.</i> 1 Leave AND 2 Half Days AND 4 Short Leaves in one month. CTC will be deducted for any extra leaves.<br />
          If you take half day or short leave and stay absent for longer, you will be marked Absent for the day.<br />
          Submit written Leave on paper by minimum 2 days in advance and 'Leave Approval' is required before taking leave, otherwise you shall be marked Absent.<br />
          If you stay Absent for regularly three days, you will be marked as 'left company' and there will be need of interview and as a new joining, as per need and requirement of company.<br />
          (For any correction, contact HR through worker, whom you contact for Leave Approvals, within maximum one working day of attendance.)
        </center>
      </div>
    </div>
  )
}
