import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios, {baseURL} from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListMaintenanceActivity() {
  const[activities,setActivities] = useState([]);

  var navigate = useNavigate();
  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getActivities();
    }
  }, [loggedIn, navigate]);

  function getActivities() {
        axios.get('/maintenance_activity.php').then(function(response){
        //console.log(response.data);
        setActivities(response.data);
    });
  }

  const deleteProject = (id) => {
          axios.delete(`/maintenance_activity.php/${id}/delete`).then(function(response){
          //console.log(response.data);
          getActivities();
      });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  var only_admin_editable = false
  if(designation === "admin"){
    only_admin_editable = true;
  }

  const[pageNumber, setPageNumber] = useState(0)
  const activitiesPerPage = 5
  const pageVisited = pageNumber * activitiesPerPage
  const pageCount = Math.ceil(activities.length / activitiesPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayMaintenanceWorkerActivity = activities
  //.filter(project => project.project_assigned_to_tl === employee_id)
  .slice(pageVisited, pageVisited + activitiesPerPage)
  .map((maintenance_activity, key) =>
    <tr key={key}>
      {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
      {maintenance_activity.employee_id === employee_id || designation === 'admin' || designation === 'assistant' ? ( <>
        <td>{maintenance_activity.work_id}</td>
        <td>
        {users
          .filter(user => user.employee_id === maintenance_activity.employee_id)
          .map((user, key) =>
          <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
        )}
        </td>
        <td>{maintenance_activity.problem_date}</td>
        <td>{maintenance_activity.problem_subject}</td>
        <td>{maintenance_activity.problem_description}</td>
        <td>{maintenance_activity.problem_type}</td>
        <td><a href={baseURL+"/images/maintenance_images/"+maintenance_activity.problem_photos} target="_blank" rel="noreferrer"><img src={baseURL+"/images/maintenance_images/"+maintenance_activity.problem_photos} alt="activity_images" width="60" height="60" /></a></td>
        <td>{maintenance_activity.solutions_available}</td>
        <td>{maintenance_activity.estimated_time_to_fix}</td>
        <td>
        {users
          .filter(user => user.employee_id === maintenance_activity.manual_inspection_by)
          .map((user, key) =>
          <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
        )}
        </td>
        <td>{maintenance_activity.solution_approved}</td>
        <td>{maintenance_activity.amount_approved}</td>
        <td>{maintenance_activity.work_status}</td>
        <td>{maintenance_activity.solution_done}</td>
        <td>{maintenance_activity.actual_spent}</td>
        <td>{maintenance_activity.refund_overcharge_amount}</td>
        <td>{maintenance_activity.final_comments_by_admin}</td>
        <td>{maintenance_activity.work_quality_score}</td>
        <td>{maintenance_activity.worker_initial_estimate_correctness_score}</td>
        <td>{maintenance_activity.complete_in_time_score}</td>
        <td>{maintenance_activity.least_charge_score}</td>
        <td>{maintenance_activity.created_at}</td>
        <td>{maintenance_activity.updated_at}</td>
        {maintenance_activity.lock_editting !== 'yes' ? ( <>
          <td>
            <div><Link to={`${maintenance_activity.work_id}/edit`} style={{marginRight: "10px"}}>Edit</Link></div>
            {only_admin_editable ? ( <>
              <div><button onClick={() => deleteProject(maintenance_activity.work_id)}>Delete</button></div>
            </> ) : ("")}
          </td>
        </> ) : (<td>Locked</td>)}
      </> ) : ("")}
    </tr>
  )

  if(designation === 'maintenance_worker' || designation === 'admin'|| designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Maintenance Worker Activity
        </h1>
        <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Employee ID</th>
                <th>Problem Date</th>
                <th>Problem Subject</th>
                <th>Problem Description</th>
                <th>Problem Type</th>
                <th>Problem Photos</th>
                <th>Solutions Available</th>
                <th>Estimated Time To Fix</th>
                <th>Manual Inspection By</th>
                <th>Solution Approved By Admin</th>
                <th>Amount Approved</th>
                <th>Work Status</th>
                <th>Solution Done</th>
                <th>Actual Spent</th>
                <th>Refund Overcharge Amount</th>
                <th>Final Comments By Admin</th>
                <th>Work Quality Score</th>
                <th>Worker Initial Estimates Correctness Score</th>
                <th>Complete InTime Score</th>
                <th>Least Charging Score</th>
                <th>Created On</th>
                <th>Updated On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayMaintenanceWorkerActivity}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
        </div>
      </div>
    )
  }
}
