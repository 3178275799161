import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'

export default function Login() {
  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")

  useEffect(() => {
    if(loggedIn !== null){
      navigate('/');
    }
  }, [loggedIn, navigate]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [designation, setDesignation] = useState('');

  const handleUsername = (e) => {
    setUsername(e.target.value);
  }
  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleDesignation = (e) => {
    setDesignation(e.target.value);
  }

  const handleSubmit = (e) => {
    //console.log({username, password, designation});
    axios.post('/login.php', {
      username: username,
      password: password,
      designation: designation
    }).then(function(response){
      console.log(response.data);

      if(response.data.status === 1){
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("designation", designation);
        sessionStorage.setItem("employee_id", response.data.data[2]);
        sessionStorage.setItem("team", response.data.data[6]);
        sessionStorage.setItem("employee_email", response.data.data[17]);
        sessionStorage.setItem("employee_phone", response.data.data[18]);
        sessionStorage.setItem("employee_second_phone", response.data.data[19]);
        sessionStorage.setItem("employee_date_of_birth", response.data.data[20]);
        sessionStorage.setItem("employee_address", response.data.data[21]);
        sessionStorage.setItem("employee_correspondence_address", response.data.data[22]);
        sessionStorage.setItem("employee_joining_date", response.data.data[23]);
        //var item_value = sessionStorage.getItem("loggedIn")
        navigate('/');
      }
      //alert(response.data.message);
    })
  }

  return (
    <div className="form">
    <h1>Login</h1>
    <table cellSpacing="10">
        <tbody>
           <tr>
                <th>
                    <label>Username: </label>
                </th>
                <td>
                    <input value={username} onChange={handleUsername} type="text" />
                </td>
            </tr>
            <tr>
                 <th>
                     <label>Password: </label>
                 </th>
                 <td>
                     <input value={password} onChange={handlePassword} type="password" />
                 </td>
             </tr>
             <tr>
                  <th>
                      <label>Login for: </label>
                  </th>
                  <td>
                    <select name="designation" onChange={handleDesignation} defaultValue={'DEFAULT'}>
                          <option value="DEFAULT" disabled>Choose a Designation.</option>
                          <option value="assistant">Assistant</option>
                          <option value="admin">Admin</option>
                          <option value="project_manager">Project Manager</option>
                          <option value="human_resource">Human Resource</option>
                          <option value="team_leader">Team Leader</option>
                          <option value="software_developer">Software Developer</option>
                          <option value="software_designer">Software Designer</option>
                          <option value="bidder">Bidder</option>
                          <option value="maintenance_worker">Maintenance Worker</option>
                    </select>
                  </td>
            </tr>
            <tr>
                <td colSpan="2" align ="right">
                  <button onClick={handleSubmit}>Login</button>
                </td>
            </tr>
          </tbody>
        </table>
    </div>
  );
}
