import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function PMInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'project_manager') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> Project Manager Main Duties (Read daily): 
        <li>Regularly review if correct data updated by TL, bidder.</li>
        <li>Compare TL/Bidder entries with Platform, read client chats, verify feedback updated correct as platform.</li>
        <li>Check TL, Bidder and employees internal sheets, passwords, documents, emails, communication with clients and employees, if it is transparent for company benefits.</li>
        <li>Check in cameras, if work done is transparent and no hidden data moved through code</li>
        <li>Verify messages, if editing deletion of project or feedback requested, which is not editable for TL/bidder.</li>
        <li>Before deletion, verify if actual project hired in hiring platform</li>
        <li>Verify from chat, etc. all modes of communication (skype id, email, other email, phone number, etc.) entered by TL are correct.</li>
        <li>If any entry wrong, use system notification system to inform TL to make correction and not to repeat again.</li>
        <li>Check cameras if phone, etc. used during work, which is not allowed.</li>
        <li>Access scores of Teams and Employees to review where progress needed.</li>
        <li>Check if project assigned to TL by bidder, accepted by TL? And if further assigned to Employee in time? </li>
        <li>Check 'Bidder Activity' entries, if they are actually made, if bidder delayed replies (to client) then warm him via notification/email. </li>
        <li>While checking Feedback, remember most clients don't give feedback or have upcoming projects. Calculated feedback is only depending on if client gave feedback. So, it shall not be correct feedback, however if clients giving -ve feedback, then its worth warning. (Need correction to calculate 'open projects' in table later)</li>
        <li>Can order HR (HR can tell to TL) to warn or fire/hire employees.</li>
        <li>When new joining, approve/disapprove salary requirement of employee and verify his documents. Update it in online</li>
        <li>Can delete faulty repeated projects entered or bidder activity if requested by team leader or bidder</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>Read Daily Activity Entry of Employees and Team Leaders, visible on dashboard</li>
        <li>To delete any entry, request Assistant by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
        <li>Only mode of communication allowed in company is through <Link to="/contact">message</Link>, to get any work done, so that each activity get noted.</li>
        <li>If any employee Absent for three days, they will be marked as 'left company'. There will be need of interview and new joining(employee_id) and can join after approval of Admin and Project Manager. You can select them cautiously, only if company needs them.</li>
        <li>Any fine taken must be mentioned in daily entry, informed to <Link to="/contact">Admin</Link> and submitted to company bank within three working days.</li>
        <li>Bonus is not allowed, but only adjustment of any absent from unpaid salary. Contact <Link to="/contact">Assistant</Link> to mark a present for employee, with employee Id and reason.</li>
      </div>
    </>
  )

  }

  export default PMInfobar;
