import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function AssistantInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'admin') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> Assistant Important Duties (Read daily):
        <li>Check if Admin and Project Manager and HR doing daily entry. Verify all payments mentioned, withdrawn from bank, etc.</li>
        <li>Check if Admin amount spent is genuine.</li>
        <li>Check if project Manager doing efforts to keep scores up.</li>
        <li>Check if employees left, Human Resource deducting salary as per days of work and if paid to employee</li>
        <li>If Project Manager, Admin or Human Resource demand for deletion of some project, user, attendance, etc. entry. Verify if similar entry with correct values is there, ask reason. If valid, then delete.</li>
        <li>Daily do your daily activity entry at end of day. It should include any flaws found in work and other activities done.</li>
        <li>Only mode of communication allowed in company is through <Link to="/contact">message</Link>, to get any work done, so that each activity get noted.</li>
        <li>Read all entries, messages of employees to view if they are genuine and respectful to others.</li>
        <li>Daily check if HR mark attendance in morning for last day, check if correct number of entries marked in Attendance list for all and for those who filled 'Daily Activities Entry' yesterday.</li>
        <li>If anyone leave company immediately, their salary wouldn't be paid. Make sure Admin put it back in company bank.</li>
        <li>If anyone absent for three days regularly. They will be marked as 'left company'. Check attendance regularly.</li>
        <li>Anyone absent for three days, can be given a new joining after interview, with new employee_id, only after approval from Project Manager and Admin.</li>
        <li>Verify Salaries paid as per 'Company Management System' calculation and if there are any fine taken, they must be submitted in company bank in three working days.</li>
        <li>Bonus given can not be more than actual attendance but can be given by adjusting any last month unpaid Absents, on basis of mail received from HR, Admin or Assistant for an employee.</li>
      </div>
    </>
  )

}

export default AssistantInfobar;
