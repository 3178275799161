import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios, {baseURL} from '../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListUsers() {

  var designation = sessionStorage.getItem("designation")

  const[users,setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const deleteUser = (id) => {
          axios.delete(`user/${id}/delete`).then(function(response){
          //console.log(response.data);
          getUsers();
      });
  }

  var editable_only_by_assistant = false
  if(designation === "assistant"){
    editable_only_by_assistant = true;
  }

  var editable_only_by_hr = false
  if(designation === "human_resource"){
    editable_only_by_hr = true;
  }

  var admin = false
  if(designation === "admin"){
    editable_only_by_hr = true;
  }

  var project_manager = false
  if(designation === "project_manager"){
    editable_only_by_hr = true;
  }

  const[pageNumber, setPageNumber] = useState(0)
  const usersPerPage = 5
  const pageVisited = pageNumber * usersPerPage
  const pageCount = Math.ceil(users.length / usersPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayUsers = users
  .slice(pageVisited, pageVisited + usersPerPage)
  .map((user, key) =>
    <tr key={key}>
      <td>{user.id}</td>
      <td>{user.candidate_id}</td>
      <td>{user.employee_id}</td>
      <td>{user.username}</td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.profile_photo} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.profile_photo} alt="profile_photo" width="60" height="60" /></a></td>
      <td>{user.designation}</td>
      <td>{user.team_allocated}</td>
      <td>{user.skills}</td>
      <td>{user.experience}</td>
      <td>{user.education}</td>
      <td>{user.documents}</td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.resume} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.resume} alt="resume" width="60" height="60" /></a></td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.adhaar_card} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.adhaar_card} alt="adhaar_card" width="60" height="60" /></a></td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.pan_card} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.pan_card} alt="pan_card" width="60" height="60" /></a></td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.qualification_document} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.qualification_document} alt="qualification_document" width="60" height="60" /></a></td>
      <td><a href={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.experience_training} target="_blank" rel="noreferrer"><img src={baseURL+"/images/candidates/"+user.candidate_id+"/"+user.experience_training} alt="experience_training" width="60" height="60" /></a></td>
      <td>{user.email}</td>
      <td>{user.mobile}</td>
      <td>{user.other_phone}</td>
      <td>{user.date_of_birth}</td>
      <td>{user.home_address}</td>
      <td>{user.correspondence_address}</td>
      <td>{user.joining_date}</td>
      <td>{user.left_date}</td>
      <td>{user.status}</td>
      <td>{user.password}</td>
      <td>{user.hr_interview_score}</td>
      <td>{user.technical_interview_score}</td>
      <td>{user.salary_discussed}</td>
      <td>{user.ctc_approved_by_admin}</td>
      <td>{user.joining_approval_by_admin}</td>
      <td>{user.joining_approval_by_project_manager}</td>
      <td>{user.created_at}</td>
      <td>
        {user.employee_id === '1' || user.employee_id === '2' || user.employee_id === '3' || user.employee_id === '4' || user.employee_id === '99999' ? (<>
          {editable_only_by_assistant ? ( <>
            <Link to={`user/${user.id}/edit`} style={{marginRight: "10px"}}>Edit</Link>
          </> ) : ("") }
        </>) : (<>
          {editable_only_by_hr || project_manager || admin ? ( <>
            <Link to={`user/${user.id}/edit`} style={{marginRight: "10px"}}>Edit</Link>
          </> ) : ("") }
        </>) }
        {editable_only_by_assistant ? ( <>
          {/*Following IDs hard-coded for notifications in APIs, so shouldn't be deleted for specific designations, however can be editted.*/}
          {user.employee_id === '1' || user.employee_id === '2' || user.employee_id === '3' || user.employee_id === '4' || user.employee_id === '99999' ? ("") : (<>
            <button onClick={() => deleteUser(user.id)}>Delete</button>
          </>) }
        </> ) : ("") }
      </td>
    </tr>
  )

  if(designation === 'human_resource'|| designation === 'assistant' || designation === 'admin' || designation === 'project_manager'){
    return (
      <div className="pagePadding">
        <h1>
          Candidates & Employees
        </h1>
        <div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Candidate Id</th>
                <th>Employee Id</th>
                <th>Username</th>
                <th>Profile Photo</th>
                <th>Designation</th>
                <th>Team Allocated</th>
                <th>Skills</th>
                <th>Work Experience</th>
                <th>Education</th>
                <th>Documents Received</th>
                <th>Adhaar Card</th>
                <th>PAN Card</th>
                <th>Resume</th>
                <th>Highest Educational Qualification</th>
                <th>Latest Experience/Training</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Other Phone</th>
                <th>Date Of Birth</th>
                <th>Home Address</th>
                <th>Correnspondence Address</th>
                <th>Joining Date</th>
                <th>Company Left Date <br />with reason</th>
                <th>Status</th>
                <th>Password</th>
                <th>HR Scores</th>
                <th>Technical Scores</th>
                <th>Salary Discussed</th>
                <th>CTC Approved By Admin</th>
                <th>Final Approval By Admin</th>
                <th>Joining Approval By Project Manager</th>
                <th>Added On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayUsers}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationButtons"}
            previousLinkClassName={"privousButton"}
            nextLinkClassName={"nextButton"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
    )
  }
// Refer from: https://dev.to/durgeshsahani/react-crud-operations-using-php-api-and-mysql-13da
}
