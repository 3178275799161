import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'
import HRMenubar from "./../../menubars/HRMenubar"


export function CreateAttendance() {
  const navigate = useNavigate();

  const inputRef = useRef(null);

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const time = current.getHours()
        + ':' + current.getMinutes()
        + ":" + current.getSeconds();

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else if (designation !== 'human_resource'){
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    var value3 = document.getElementById("dropdown_id3");
    var getvalue3 = value3.options[value3.selectedIndex].value;

    var value4 = document.getElementById("dropdown_id4");
    var getvalue4 = value4.options[value4.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT" || getvalue3 === "DEFAULT" || getvalue4 === "DEFAULT"){
      alert("Fill required fields.")
    }
    else {
      axios.put('/attendance.php', inputs).then(function(response){
        //console.log(response.data);
        navigate('/users/listattendance'); //After saving, redirect to list of users
      });
    }
  }

  return (
    <div className="pagePadding">
      <div className="left">
        <HRMenubar />
      </div>
      <h1>Create Attendance</h1>
      <div>
        <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
            <tbody>
                <tr>
                    <th>
                        <label>Select Employee ID*: </label>
                    </th>
                    <td>
                      <select name="e_id" ref={inputRef} onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id1" >
                        <option value="DEFAULT" disabled>Select</option>
                        {users
                          .sort((a, b) => a.employee_id - b.employee_id)
                          .map((user, key) =>
                          <option key={key} value={"eid_"+user.employee_id}>{user.employee_id} {user.username} {user.designation}</option>
                        )}
                      </select>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label>Select Date*: </label>
                    </th>
                    <td>
                      <select name="date" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id2" >
                        <option value="DEFAULT" disabled>Select</option>
                        <option value={date}>{date}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Attendance Update Time</label>
                   </th>
                   <td>
                     <select name="update_time" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id3" >
                       <option value="DEFAULT" disabled>Select</option>
                       <option value={time}>{time}</option>
                     </select>
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Status</label>
                   </th>
                   <td>
                     <select name="eid_1" onChange={handleChange} defaultValue={'DEFAULT'} id="dropdown_id4" >
                       <option value="DEFAULT" disabled>Select</option>
                       <option value="P">Present</option>
                       <option value="H">Half Day</option>
                       <option value="S">Short Leave (Quarter Day)</option>
                       <option value="L">Full Day On Leave</option>
                       <option value="A">Absent</option>
                     </select>
                   </td>
                </tr>
                <tr>
                    <td colSpan="2" align ="right">
                        <button>Save</button>
                    </td>
                </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  )
}
