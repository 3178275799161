import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import HRMenubar from "./../menubars/HRMenubar"
import BidderMenubar from "./../menubars/BidderMenubar"
import TLMenubar from "./../menubars/TLMenubar"
import PMMenubar from "./../menubars/PMMenubar"
import MaintenanceWorkerMenubar from "./../menubars/MaintenanceWorkerMenubar"
import AdminMenubar from "./../menubars/AdminMenubar"
import AssistantMenubar from "./../menubars/AssistantMenubar"
import CommonInfobar from "./../menubars/infobars/CommonInfobar"
import HRInfobar from "./../menubars/infobars/HRInfobar"
import TLInfobar from "./../menubars/infobars/TLInfobar"
import BidderInfobar from "./../menubars/infobars/BidderInfobar"
import PMInfobar from "./../menubars/infobars/PMInfobar"
import MaintenanceInfobar from "./../menubars/infobars/MaintenanceInfobar"
import AdminInfobar from "./../menubars/infobars/AdminInfobar"
import AssistantInfobar from "./../menubars/infobars/AssistantInfobar"
import EmployeeInfo from "./employee_management/views/EmployeeInfo"

export function Home() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  var menuLeft;
  var menuRight;

  if(designation === 'human_resource'){
    menuLeft = <div className="left"> <HRMenubar /> <br /> <CommonInfobar /> <br /> <HRInfobar /> </div>
  }
  else if(designation === 'bidder'){
    menuLeft = <div className="left"> <BidderMenubar /> <br /> <CommonInfobar /> <br /> <BidderInfobar /> </div>
  }
  else if(designation === 'team_leader'){
    menuLeft = <div className="left"> <TLMenubar /> <br /> <CommonInfobar /> <br /> <TLInfobar /> </div>
  }
  else if(designation === 'project_manager'){
    menuLeft = <div className="left"> <PMMenubar /> <br /> <CommonInfobar /> <br /> <PMInfobar /> </div>
  }
  else if(designation === 'maintenance_worker'){
    menuLeft = <div className="left"> <MaintenanceWorkerMenubar /> <br /> <CommonInfobar /> <br /> <MaintenanceInfobar /> </div>
  }
  else if(designation === 'admin'){
    menuLeft = <div className="left"> <AdminMenubar /> <br /> <CommonInfobar /> <br /> <AdminInfobar /> </div>
  }
  else if(designation === 'assistant'){
    menuLeft = <div className="left"> <AssistantMenubar /> <br /> <CommonInfobar /> <br /> <AssistantInfobar /> </div>
  }
  else {
    menuLeft = ""
  }

  if(designation !== 'human_resource' && designation !== 'bidder' && designation !== 'team_leader' && designation !== 'project_manager' && designation !== 'maintenance_worker' && designation !== 'admin' && designation !== 'assistant'){
    menuRight = <div className="right"> <CommonInfobar /> </div>
  }
  else {
    menuRight = ""
  }

  return (
    <>
      <div className="pagePadding">
        <div className="pageLeftMenu">
          {menuLeft}
        </div>
        <div className="pageContent">
          <EmployeeInfo />
        </div>
        <div className="pageRightMenu">
          {menuRight}
        </div>
      </div>
    </>
  )
}
