import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'

function Notifications() {
  const[messages,setMessages] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getMessages();
    }
  }, [loggedIn, navigate]);

  function getMessages() {
        axios.get('/messages.php').then(function(response){
        //console.log(response.data);
        setMessages(response.data);
    });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  return (
    <div className="pagePadding">
      <h2>Notifications</h2>
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Message Type</th>
              <th>From</th>
              <th>To</th>
              <th>CC</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Sent Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {messages
              .map((message, key) =>
              <tr key={key}>
                {message.sent_to === employee_id && message.message_type === "notification" ? ( <>
                  <td>{message.id}</td>
                  <td>{message.message_type}</td>
                  {users
                    .filter(user => user.employee_id === message.sent_from)
                    .map((user, key) =>
                    <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
                  )}
                  {users
                    .filter(user => user.employee_id === message.sent_to)
                    .map((user, key) =>
                    <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
                  )}
                  <td>{message.cc}</td>
                  <td>{message.subject}</td>
                  <td>{message.message_body}</td>
                  <td>{message.created_at}</td>
                </> ) : ("")}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Notifications;
