import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateMessage from "./notification_management/CreateMessage"

export function Contact() {

  var loggedIn = sessionStorage.getItem("loggedIn")
  //var designation = sessionStorage.getItem("designation")
  var navigate = useNavigate();

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  return (
    <>
      <h1>Contact</h1>
      <br /><br />
      <div><CreateMessage /></div>
      <center>
      <h2>Direct email:</h2>
        <h3>For any issues in 'Company Management System': <a href="mailto: admin@ziscom.in">support@ziscom.in</a></h3>
      </center>
    </>
  )
}
