import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import MaintenanceWorkerMenubar from "./../../../menubars/MaintenanceWorkerMenubar"

export function CreateMaintenanceActivity() {
  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")
  var username = sessionStorage.getItem("username")
  var logged_in_user_info = username+' ('+employee_id+') '+designation

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else if(designation !== 'maintenance_worker' && designation !== 'admin'){
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));

  }

  const[file, setFile] = useState('')
  const handleFile = (event) => {
  	const file = event.target.files[0]
  	//console.log(file)
  	setFile({
  		picturePreview: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file.name;

    setInputs(values => ({...values, [name]: value}));
  }

  function handleSubmit(event){
    event.preventDefault();

    let formData = new FormData();
  	formData.append('file_attachment', file.pictureAsFile)
    formData.append('file_type', 'maintenance_activity')

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT"){
      alert("Fill required fields.")
    }
    else {

      axios({
    		url: '/upload_file.php',
    		method:'post',
    		headers: {
    			'Content-Type': 'multipart/form-data'
    		},
    		data: formData,
    	}).then(function(response){
        if(response.data.status === 0){
          alert(response.data.message)
        } else {
          axios.post('/maintenance_activity.php', inputs).then(function(response){
            //console.log(response.data);
            if(response.data.status === 0){
              alert(response.data.message)
            } else {
              alert(response.data.message)
              navigate('/listmaintenanceactivity/'); //After saving, redirect to list of users
            }
          });
        }
    	})
    }
  }

  return (
    <div className="pagePadding">
      <div className="left">
        <MaintenanceWorkerMenubar />
      </div>
      <h1>Maintenance Worker Activity</h1>
      <h2>(Update all entries at end of day)</h2>
      <div>
        <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
            <tbody>
                <tr>
                    <th>
                        <label>Employee ID*: </label>
                    </th>
                    <td>
                      <select name="e_id" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                        <option value="DEFAULT" disabled>Choose</option>
                        <option value={employee_id}>{logged_in_user_info}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Problem Date*: </label>
                   </th>
                   <td>
                       <input type="date" name="problem_date" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Project Subject*: </label>
                   </th>
                   <td>
                       <input type="text" name="problem_subject" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Issue Description Along With Reason Of Issue<br />(500 words Max.)*: </label>
                   </th>
                   <td>
                       <textarea name="problem_description" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Problem Type*: </label>
                   </th>
                   <td>
                     <select name="problem_type" id="dropdown_id2" onChange={handleChange} defaultValue={'DEFAULT'} >
                     <option value="DEFAULT" disabled>Choose</option>
                     <option value="urgent">Urgent</option>
                     <option value="normal">Normal</option>
                     <option value="can_delay">Can Delay</option>
                     </select>
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Problem Photo (Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="file" onChange={handleFile} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Solutions Available (Mention amount, if any)*: </label>
                   </th>
                   <td>
                       <textarea name="solutions_available" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Estimated Time To Fix*: </label>
                   </th>
                   <td>
                     <input type="text" name="estimated_time_to_fix" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <td colSpan="2" align ="right">
                        <button>Save</button>
                    </td>
                </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  )
}
