import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function EmployeeInfo() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")
  var employee_email = sessionStorage.getItem("employee_email")
  var employee_phone = sessionStorage.getItem("employee_phone")
  var employee_second_phone = sessionStorage.getItem("employee_second_phone")
  var employee_date_of_birth = sessionStorage.getItem("employee_date_of_birth")
  var employee_address = sessionStorage.getItem("employee_address")
  var employee_correspondence_address = sessionStorage.getItem("employee_correspondence_address")
  var employee_joining_date = sessionStorage.getItem("employee_joining_date")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  return (
    <>
        <div className="right"> <b>Welcome {username}</b> </div>
        <br />
        <div className="right"> <b>{designation}</b> </div>
        <br />
        <div className="right"> <b>Employee Id:</b> {employee_id} </div>
        <br />
        <div className="left"> <b>Contact details:</b> </div>
        <br />
        <div className="left"> <b>Email:</b> {employee_email} </div>
        <br />
        <div className="left"> <b>Phone:</b> {employee_phone} </div>
        <br />
        <div className="left"> <b>Second Phone:</b> {employee_second_phone} </div>
        <br /><br />
        <div className="left"> <b>Date Of Birth:</b> {employee_date_of_birth} </div>
        <br /><br />
        <div className="left"> <b>Address:</b> {employee_address} </div>
        <br /><br />
        <div className="left"> <b>Correspondence Address:</b> {employee_correspondence_address} </div>
        <br /><br />
        <div className="left"> <b>Joining Date:</b> {employee_joining_date} </div>
        <br /><br />
        <div className="left"> (*To update your details contact HR through <Link to="/contact">message</Link>.)</div>
    </>
  )
}

export default EmployeeInfo;
