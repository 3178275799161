import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function BidderInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'bidder') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> Bidder Important Duties (Read daily):
        <li>Add project details</li>
        <li>Aim for least bidding charges and maximum successfull projects hired.</li>
        <li>Always communicate with TL with type of projects required.</li>
        <li>Always communicate with TL a second time before hire finalized, if they have resources for it, otherwise ask other team Leader and if not possible, try to delay by a day with client before hire.</li>
        <li>Contact HR two days in advance, if need of bidding points.</li>
        <li>Contact Team Leader if client have urgent technical questions.</li>
        <li>Regularly update total bids made, total spent, date.</li>
        <li>It is good to ping clients after a day or few days with 'Hi', to ask if they have new projects, only if they replies to 'Hi'.</li>
        <li>There should be 'Daily Activity' entry for every bid made, for every client communication, for every hire, etc. Save activity in doc and update all entries in end of day.</li>
        <li>If wrong entry made in 'Daily Activity', then create new correct activity entry with comments mentioning, last entry ID which was wrong.</li>
        <li>Don't interfere with other Bidder clients, until necessary(if other Bidder on holiday or urgent communication demanded by client or other bidder not communicating from two weeks.)</li>
        <li>If hired client's project feedback didn't come yet, or he ignored completed project feedback, don't communicate such client until his work finalized from more than one week and client was happy with work.</li>
        <li>Regularly watch computer to reply immediately. Clients contact other bidders if not replied immediately.</li>
        <li>Can request project manager to delete a faulty activity entry</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>To delete any entry, request Project Manager by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
      </div>
    </>
  )

  }

  export default BidderInfobar;
