import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function TLInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'team_leader') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> TL Important Duties (Read daily):
        <li>Update Project details</li>
        <li>Aim for 100% result from Projects (and if possible, reoccuring clients) through Team</li>
        <li>Update Project and Employee feedback</li>
        <li>Can perform team birthday parties by team member amount collection at half time.</li>
        <li>Can request project manager to delete a faulty project entry</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>Verify daily activity entry done by team workers correctly and for correct work done.</li>
        <li>To delete any entry, request Project Manager by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
        <li>Only mode of communication allowed in company is through <Link to="/contact">message</Link>, to get any work done, so that each activity get noted.</li>
        <li>While approving leaves, make sure, only one employee of team can take leave in a day and if necessary.</li>
        <li>Fine taking is not allowed, however only if told by Admin, HR or Project Manager. Any fine taken details should be entered in 'daily entry' and and messaged to <Link to="/contact">HR and Admin</Link> and should be submitted to HR.</li>
        <li>For any correction in wrong attendance marked, <Link to="/contact">message HR</Link>, within one working day of attendance.</li>
      </div>
    </>
  )

  }

  export default TLInfobar;
