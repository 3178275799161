import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'

export function DailyActivities() {
  const navigate = useNavigate();

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const time = current.getHours()
        + ':' + current.getMinutes()
        + ":" + current.getSeconds();
  const date_time = date+' '+time;

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var team = sessionStorage.getItem("team")
  var employee_id = sessionStorage.getItem("employee_id")
  var username = sessionStorage.getItem("username")
  var logged_in_user_info = username+' ('+employee_id+')'

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
  }, [loggedIn, designation, navigate]);

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}));

  }

  const handleSubmit = (event) => {
    event.preventDefault();

      axios.post('/daily_activities_entry.php', inputs).then(function(response){
        //console.log(response.data);
        if(response.data.status === 1){
          alert(response.data.message)
          navigate('/logout');
        }
        else {
          alert('Fill all fields')
        }
      });
  }

  return (
    <div className="pagePadding">
      <h1>Daily Activities Entry</h1>
      <h2>(Make entry during last few minutes before leaving.)</h2>
      <div>
        <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
            <tbody>
                <tr>
                  <th>
                    <label>Sign out Time*</label>
                  </th>
                  <td>
                    <select name="date_time" onChange={handleChange} defaultValue={'DEFAULT'} required >
                      <option value="DEFAULT" disabled>Select</option>
                      <option value={date_time}>{date_time}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                    <th>
                        <label>Employee*: </label>
                    </th>
                    <td>
                      <select name="employee_id" onChange={handleChange} defaultValue={'DEFAULT'} required >
                        <option value="DEFAULT" disabled>Select</option>
                        <option value={employee_id}>{logged_in_user_info}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label>Designation*: </label>
                    </th>
                    <td>
                      <select name="designation" onChange={handleChange} defaultValue={'DEFAULT'} required >
                        <option value="DEFAULT" disabled>Select</option>
                        <option value={designation}>{designation}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                    <th>
                        <label>Team*: </label>
                    </th>
                    <td>
                      <select name="team" onChange={handleChange} defaultValue={'DEFAULT'} required >
                        <option value="DEFAULT" disabled>Select</option>
                        <option value={team}>{team}</option>
                      </select>
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Daily Activities Entry*: </label>
                   </th>
                   <td>
                       <textarea maxLength="299" name="daily_activities" rows="3"cols="100" onChange={handleChange} defaultValue="Today I worked on 1) " />
                   </td>
                </tr>
                <tr>
                    <td colSpan="2" align ="right">
                        <button>Save and Sign Out</button>
                    </td>
                </tr>
            </tbody>
          </table>
        </form>
        <h2>Enter names of all projects, clients involved and actual work done(in short), in daily entry.</h2>
        <h2><Link to="/listdailyactivities">Click here to read your daily activity entries</Link></h2>
      </div>
    </div>
  )
}
