import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ViewAllMessages() {
  const[messages,setMessages] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getMessages();
    }
  }, [loggedIn, navigate]);

  function getMessages() {
        axios.get('/messages.php').then(function(response){
        //console.log(response.data);
        setMessages(response.data);
    });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const[pageNumber, setPageNumber] = useState(0)
  const messagesPerPage = 5
  const pageVisited = pageNumber * messagesPerPage
  const pageCount = Math.ceil(users.length / messagesPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayMessages = messages
  .slice(pageVisited, pageVisited + messagesPerPage)
  .map((message, key) =>
    <tr key={key}>
      <td>{message.id}</td>
      <td>{message.message_type}</td>
      {users
        .filter(user => user.employee_id === message.sent_from)
        .map((user, key) =>
        <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
      )}
      {users
        .filter(user => user.employee_id === message.sent_to)
        .map((user, key) =>
        <td key={key}>{user.username} <br />{user.designation}({user.employee_id})</td>
      )}
      <td>{message.cc}</td>
      <td>{message.subject}</td>
      <td>{message.message_body}</td>
      <td>{message.created_at}</td>
    </tr>
  )

  if(designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>View All Messages And Notifications Sent</h1>
        <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Message Type</th>
                <th>From</th>
                <th>To</th>
                <th>CC</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Sent Date/Time</th>
              </tr>
            </thead>
            <tbody>
              {displayMessages}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
        </div>
      </div>
    )
  }
}
