import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListDailyActivities() {
  const[dailyactivity,setdailyactivity] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var employee_id = sessionStorage.getItem("employee_id")
  var designation = sessionStorage.getItem("designation")
  var team = sessionStorage.getItem("team")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getdailyactivity();
    }
    // eslint-disable-next-line
  }, [loggedIn, navigate]);

  function getdailyactivity() {
        axios.get('/daily_activities_entry.php').then(function(response){
          //console.log(response.data);
          // eslint-disable-next-line
          const newArray = response.data.map(obj => {
            if(designation === "admin"){
              if(obj.designation === "maintenance_worker" || obj.designation === "human_resource"){
                const id = obj.id;
                const created_at = obj.created_at;
                const employee_id = obj.employee_id;
                const designation = obj.designation;
                const team = obj.team;
                const daily_activities = obj.daily_activities;
                return { id, created_at, employee_id, designation, team, daily_activities }
              }
            }
            else if(designation === "project_manager"){
              if(obj.designation === "team_leader" || obj.designation === "software_developer" || obj.designation === "software_designer" || obj.designation === "bidder" || obj.designation === "human_resource"){
                  const id = obj.id;
                  const created_at = obj.created_at;
                  const employee_id = obj.employee_id;
                  const designation = obj.designation;
                  const team = obj.team;
                  const daily_activities = obj.daily_activities;
                  return { id, created_at, employee_id, designation, team, daily_activities }
              }
            }
            else if(designation === "human_resource"){
              if(obj.designation === "team_leader" || obj.designation === "software_developer" || obj.designation === "software_designer" || obj.designation === "bidder" || obj.designation === "maintenance_worker"){
                  const id = obj.id;
                  const created_at = obj.created_at;
                  const employee_id = obj.employee_id;
                  const designation = obj.designation;
                  const team = obj.team;
                  const daily_activities = "";
                  return { id, created_at, employee_id, designation, team, daily_activities }
                }
            }
            else if(designation === "team_leader"){
              if(obj.team === team){
                const id = obj.id;
                const created_at = obj.created_at;
                const employee_id = obj.employee_id;
                const designation = obj.designation;
                const team = obj.team;
                const daily_activities = obj.daily_activities;
                return { id, created_at, employee_id, designation, team, daily_activities }
              }
            }
            else if(designation === "assistant"){
                  const id = obj.id;
                  const created_at = obj.created_at;
                  const employee_id = obj.employee_id;
                  const designation = obj.designation;
                  const team = obj.team;
                  const daily_activities = obj.daily_activities;
                  return { id, created_at, employee_id, designation, team, daily_activities }
            }
            else {
              if(obj.employee_id === employee_id){
                const id = obj.id;
                const created_at = obj.created_at;
                const employee_id = obj.employee_id;
                const designation = obj.designation;
                const team = obj.team;
                const daily_activities = obj.daily_activities;
                return { id, created_at, employee_id, designation, team, daily_activities }
              }
            }
          })
        console.log(newArray)
        setdailyactivity(newArray);
    });
  }

  const[pageNumber, setPageNumber] = useState(0)
  const activitiesPerPage = 5
  const pageVisited = pageNumber * activitiesPerPage
  const pageCount = Math.ceil(dailyactivity.filter(n => n).length / activitiesPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayActivities = dailyactivity
  .filter(activity => activity)
  .slice(pageVisited, pageVisited + activitiesPerPage)
  .map((activity, key) =>
    <tr key={key}>
      <td>{activity.id}</td>
      <td>{activity.created_at}</td>
      <td>{activity.employee_id}</td>
      <td>{activity.designation}</td>
      <td>{activity.team}</td>
      <td>{activity.daily_activities}</td>
    </tr>
  )

  return (
    <div className="pagePadding">
      <h1>Daily Activity</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Sign Out Time</th>
              <th>Employee (EID)</th>
              <th>Designation</th>
              <th>Team</th>
              <th>Daily Activities Entry</th>
            </tr>
          </thead>
          <tbody>
            {displayActivities}
          </tbody>
        </table>
        <ReactPaginate
        	previousLabel={"Previous"}
        	nextLabel={"Next"}
        	pageCount={pageCount}
        	onPageChange={changePage}
        	containerClassName={"paginationButtons"}
        	previousLinkClassName={"privousButton"}
        	nextLinkClassName={"nextButton"}
        	disabledClassName={"paginationDisabled"}
        	activeClassName={"paginationActive"}
        />
      </div>
    </div>
  )
}
