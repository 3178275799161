import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js';

export function EmployeeFeedback() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To get projects info, from database*/}
  const[feedbacks,setFeedbacks] = useState([]);
  useEffect(() => {
      getFeedbacks();
  }, [loggedIn, designation, navigate]);
  function getFeedbacks() {
        axios.get('/feedback.php/employeefeedback').then(function(response){
        //console.log(response.data);
        const newArray = response.data.map(obj => {
          const project_assigned_to_employee = obj.project_assigned_to_employee;
          const ClientScore = parseFloat(obj.ClientScore) / parseFloat(obj.Count);
          const BehaviorScore = (parseFloat(obj.BehaviorScore)/(parseFloat(obj.Count) * 10)) * 100
          const DedicationScore = (parseFloat(obj.DedicationScore)/(parseFloat(obj.Count) * 10)) * 100
          const TotalProjects = parseFloat(obj.Count);
          return { project_assigned_to_employee, TotalProjects, ClientScore, BehaviorScore, DedicationScore, FinalScore: (parseFloat(ClientScore+BehaviorScore+DedicationScore)/3)}
        })
        //console.log(newArray)
        setFeedbacks(newArray);
    });
  }

  // eslint-disable-next-line
  {/*To display employee info, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  if(designation === 'project_manager' || designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Employee Feedback
        </h1>
        <div className="right"> <b>Welcome {username}</b> </div>
          <br />
          <div className="right"> <b>{designation}</b> </div>
          <br />
          <div className="right"> <b>Employee Id:</b> {employee_id} </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Status</th>
                <th>Employee Name</th>
                <th>Designation</th>
                <th>Team</th>
                <th>Total client Feedback %</th>
                <th>Behavior In Team %</th>
                <th>Dedicated To Work Score %</th>
                <th>Total Projects Closed</th>
                <th>Final Scores (% Calculated by System)</th>
                <th>Feedback not received from open projects</th>
              </tr>
            </thead>
            <tbody>
            {feedbacks
              .map((feedback, key) =>
                <tr key={key}>
                  {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
                    <td>{feedback.project_assigned_to_employee}</td>
                    {users
                      .filter(user => user.employee_id === feedback.project_assigned_to_employee)
                      .map((user, key) =>
                      <td key={key}>{user.status}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.project_assigned_to_employee)
                      .map((user, key) =>
                      <td key={key}>{user.username}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.project_assigned_to_employee)
                      .map((user, key) =>
                      <td key={key}>{user.designation}</td>
                    )}
                    {users
                      .filter(user => user.employee_id === feedback.project_assigned_to_employee)
                      .map((user, key) =>
                      <td key={key}>{user.team_allocated}</td>
                    )}
                    <td>{feedback.ClientScore}</td>
                    <td>{feedback.BehaviorScore}</td>
                    <td>{feedback.DedicationScore}</td>
                    <td>{feedback.TotalProjects}</td>
                    <td>{feedback.FinalScore}</td>
                </tr>
              )}
            </tbody>
          </table>
      </div>
    )
  }
}
