import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from '../../../api/axios.js'
import TLMenubar from "./../../../menubars/TLMenubar"

export function UpdateProject() {

    const navigate = useNavigate();
    const {id} = useParams();
    var loggedIn = sessionStorage.getItem("loggedIn")
    var designation = sessionStorage.getItem("designation")

    const[projects, setProjects] = useState([]);
    useEffect(() => {
      if(loggedIn === null){
        navigate('/login');
      }
      else {
        getProjects()
      }
      // eslint-disable-next-line
    }, [loggedIn, designation, navigate]);
    function getProjects() {
        axios.get(`projects.php/listprojects/project/${id}`).then(function(response) {
            //console.log(response.data);
            setProjects(response.data);
        });
    }

    // eslint-disable-next-line
    {/*To display employee in dropdown, from database*/}
    const[users,setUsers] = useState([]);
    useEffect(() => {
      getUsers();
    }, []);
    function getUsers() {
      axios.get('/users.php').then(function(response){
          //console.log(response.data);
          setUsers(response.data);
      });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setProjects(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`projects.php/project/${id}/edit`, projects).then(function(response){
            //console.log(response.data);
            navigate('/listprojects/');
        });
    }

    return (
        <div className="pagePadding">
          <div className="left">
            <TLMenubar />
          </div>
          <h1>Edit Project</h1>
          <div>
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                             <th>
                                 <label>ID*: </label>
                             </th>
                             <td>
                                 <div> {projects.id} </div>
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Project Name*: </label>
                              </th>
                              <td>
                                  <input value={projects.project_name} type="text" name="project_name" onChange={handleChange} />
                              </td>
                        </tr>
                        <tr>
                             <th>
                                 <label>Client Name: </label>
                             </th>
                             <td>
                                  <input value={projects.client_name} type="text" name="client_name" onChange={handleChange} />
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Project Details*: </label>
                              </th>
                              <td>
                                  <textarea value={projects.project_details} name="project_details" onChange={handleChange} />
                              </td>
                          </tr>
                          <tr>
                               <th>
                                   <label>Hourly/Fixed Price*: </label>
                               </th>
                               <td>
                                   <select value={projects.hourly_fixed_price} name="hourly_fixed_price" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                                     <option value="DEFAULT" disabled>Choose a Type.</option>
                                     <option value="hourly">Hourly</option>
                                     <option value="fixed_price">Fixed Price</option>
                                   </select>
                               </td>
                           </tr>
                           <tr>
                                <th>
                                    <label>Amount*: </label>
                                </th>
                                <td>
                                    <input value={projects.amount} type="text" name="amount" onChange={handleChange} />
                                </td>
                          </tr>
                          <tr>
                               <th>
                                   <label>NOC required*: </label>
                               </th>
                               <td>
                                   <select value={projects.noc_required} name="noc_required" id="dropdown_id2" onChange={handleChange} defaultValue={'DEFAULT'} >
                                     <option value="DEFAULT" disabled>Choose</option>
                                     <option value="yes">Yes</option>
                                     <option value="no">No</option>
                                   </select>
                               </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Project Bid/Created By*: </label>
                              </th>
                              <td>
                                  {users
                                    .filter(user => user.employee_id === projects.project_bid_by)
                                    .map((user, key) =>
                                    <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                                  )}
                              </td>
                        </tr>
                        <tr>
                             <th>
                                 <label>Fresh/Reocurring Client*: </label>
                             </th>
                             <td>
                                 <select value={projects.fresh_client_or_recurring_client} name="fresh_client_or_recurring_client" id="dropdown_id4" onChange={handleChange} defaultValue={'DEFAULT'} >
                                   <option value="DEFAULT" disabled>Client type.</option>
                                   <option value="fresh">Fresh</option>
                                   <option value="returning">Returning</option>
                                 </select>
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Project Source Platform: </label>
                              </th>
                              <td>
                                  <div> {projects.project_source_platform} </div>
                              </td>
                          </tr>
                        <tr>
                            <th>
                                <label>Project Start Date*: </label>
                            </th>
                            <td>
                                <input value={projects.project_assign_date} type="date" name="project_assign_date" onChange={handleChange} />
                            </td>
                        </tr>
                        <tr>
                             <th>
                                 <label>Project Assigned To Team*: </label>
                             </th>
                               {users
                                 .filter(user => projects.project_assigned_to_tl === user.employee_id)
                                 .map((user, key) =>
                                 <td key={key}>{user.team_allocated}</td>
                               )}
                         </tr>
                         <tr>
                              <th>
                                  <label>Project Assigned To TL: </label>
                              </th>
                              <td>
                                  {users
                                    .filter(user => user.employee_id === projects.project_assigned_to_tl)
                                    .map((user, key) =>
                                    <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                                  )}
                              </td>
                          </tr>
                          <tr>
                              <th>
                                  <label>Project Assigned To Employee: </label>
                              </th>
                              <td>
                              <select value={projects.project_assigned_to_employee} name="project_assigned_to_employee" id="dropdown_id1" onChange={handleChange} defaultValue={'DEFAULT'} >
                                <option value="DEFAULT" disabled>Select Employee</option>
                                {users
                                  .filter((user) => user.designation ==='software_developer' || user.designation ==='software_designer')
                                  .map((user, key) =>
                                  <option key={key} value={user.employee_id}>{user.username} ({user.employee_id}) {user.designation}</option>
                                )}
                              </select>
                              </td>
                          </tr>
                        <tr>
                             <th>
                                 <label>Client Communication Modes(Client and our Skype ID(id;id), phone(999999999;999999999), emails used(a@gmai.com;b.gmail.com,c@gmail.com) )*: </label>
                             </th>
                             <td>
                                 <textarea value={projects.client_communication_modes} name="client_communication_modes" onChange={handleChange} />
                             </td>
                         </tr>
                        <tr>
                             <th>
                                 <label>Project End or Final Feedback Date*: </label>
                             </th>
                             <td>
                                 <input value={projects.project_final_feedback_date} type="date" name="project_final_feedback_date" onChange={handleChange} />
                             </td>
                         </tr>
                        <tr>
                             <th>
                                 <label>Client Scores on Source Platform (Calculate in %)*: </label>
                             </th>
                             <td>
                                 <textarea value={projects.project_feedback_score_on_platform_overall_percentage} name="project_feedback_score_on_platform_overall_percentage" onChange={handleChange} />
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>TL Feedback Description(about client, Client scores on source platform, if other employees involved in reason of feedback, mention their employee id, reason of score, etc.) Max:1000 words*: </label>
                              </th>
                              <td>
                                  <textarea value={projects.tl_feedback_description} name="tl_feedback_description" onChange={handleChange} />
                              </td>
                          </tr>
                        <tr>
                             <th>
                                 <label>Employee Behavior in team (Score out of 10): </label>
                             </th>
                             <td>
                              <input value={projects.employee_behavior_in_team} type="text" name="employee_behavior_in_team" onChange={handleChange} />
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Employee Dedication to work (Score out of 10): </label>
                              </th>
                              <td>
                                <input value={projects.employee_dedication_to_work} type="text" name="employee_dedication_to_work" onChange={handleChange} />
                              </td>
                          </tr>
                       <tr>
                             <th>
                                 <label>Created At: </label>
                             </th>
                             <td>
                                 <div>{projects.created_at}</div>
                             </td>
                         </tr>
                       <tr>
                            <th>
                                <label>Updated At: </label>
                            </th>
                            <td>
                                <div>{projects.updated_at}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align ="right">
                                <button>Save</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
          </div>
        </div>
    )
}
