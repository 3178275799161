import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios, {baseURL} from '../../../api/axios.js'
import MaintenanceWorkerMenubar from "./../../../menubars/MaintenanceWorkerMenubar"

export function UpdateMaintenanceActivity() {

    const navigate = useNavigate();
    const {id} = useParams();
    var loggedIn = sessionStorage.getItem("loggedIn")
    var designation = sessionStorage.getItem("designation")

    var only_admin_editable = false
    if(designation === "admin"){
      only_admin_editable = true;
    }

    var only_worker_editable = false
    if(designation === "maintenance_worker"){
      only_worker_editable = true;
    }

    const[activities, setActivities] = useState([]);
    useEffect(() => {
      if(loggedIn === null){
        navigate('/login');
      }
      else {
        getActivities()
      }
      // eslint-disable-next-line
    }, [loggedIn, designation, navigate]);
    function getActivities() {
        axios.get(`maintenance_activity.php/listmaintenanceactivity/${id}/edit`).then(function(response) {
            //console.log(response.data);
            setActivities(response.data);
        });
    }

    // eslint-disable-next-line
    {/*To display employee in dropdown, from database*/}
    const[users,setUsers] = useState([]);
    useEffect(() => {
      getUsers();
    }, []);
    function getUsers() {
      axios.get('/users.php').then(function(response){
          //console.log(response.data);
          setUsers(response.data);
      });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setActivities(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`maintenance_activity.php/activity/${id}/edit`, activities).then(function(response){
            //console.log(response.data);
            navigate('/listmaintenanceactivity/');
        });
    }

    return (
        <div className="pagePadding">
          <div className="left">
            <MaintenanceWorkerMenubar />
          </div>
          <h1>Edit Project</h1>
          <div>
            <form onSubmit={handleSubmit}>
                <table cellSpacing="10">
                  {activities.lock_editting !== 'yes' ? ( <>
                      <tbody>
                          <tr>
                               <th>
                                   <label>Work ID: </label>
                               </th>
                               <td>
                                   <div> {activities.work_id} </div>
                               </td>
                           </tr>
                           <tr>
                                <th>
                                    <label>Problem Photo: </label>
                                </th>
                                <td>
                                    <img src={baseURL+"/images/maintenance_images/"+activities.problem_photos} alt="activity_images" width="60" height="60" />
                                </td>
                          </tr>
                          {only_admin_editable ? (
                            <tr>
                                 <th>
                                     <label>Manual Inspection By: </label>
                                 </th>
                                 <td>
                                      <select value={activities.manual_inspection_by} name="manual_inspection_by" onChange={handleChange} defaultValue={'DEFAULT'} >
                                        <option value="DEFAULT" disabled>Select Employee</option>
                                        {users
                                          .filter((user) => user.designation ==='human_resource' || user.designation ==='admin')
                                          .map((user, key) =>
                                          <option key={key} value={user.employee_id}>{user.username} ({user.employee_id}) {user.designation}</option>
                                        )}
                                      </select>
                                 </td>
                             </tr>
                          ) : ("")}
                          {only_admin_editable ? (
                             <tr>
                                  <th>
                                      <label>Solution Approved By Admin<br />(200 words Max.): </label>
                                  </th>
                                  <td>
                                      <textarea value={activities.solution_approved} name="solution_approved" onChange={handleChange} />
                                  </td>
                              </tr>
                            ) : ("")}
                            {only_admin_editable ? (
                               <tr>
                                    <th>
                                        <label>Amount Approved By Admin (if any): </label>
                                    </th>
                                    <td>
                                        <input type="number" value={activities.amount_approved} name="amount_approved" onChange={handleChange} />
                                    </td>
                                </tr>
                              ) : ("")}
                            {only_worker_editable ? (
                              <tr>
                                   <th>
                                       <label>Work Status: </label>
                                   </th>
                                   <td>
                                       <select value={activities.work_status} name="work_status" onChange={handleChange} defaultValue={'DEFAULT'} >
                                         <option value="DEFAULT" disabled>Choose a Type.</option>
                                         <option value="waiting_approval">Waiting Approval</option>
                                         <option value="working">Working</option>
                                         <option value="done">Done</option>
                                       </select>
                                   </td>
                               </tr>
                             ) : ("")}
                             {only_worker_editable ? (
                               <tr>
                                    <th>
                                        <label>Explain Solution Done<br />(200 words Max.): </label>
                                    </th>
                                    <td>
                                        <textarea value={activities.solution_done} name="solution_done" onChange={handleChange} />
                                    </td>
                              </tr>
                            ) : ("")}
                            {only_admin_editable ? (
                              <tr>
                                   <th>
                                       <label>Actual Spent (Fill at end): </label>
                                   </th>
                                   <td>
                                       <input value={activities.actual_spent} type="number" name="actual_spent" onChange={handleChange} />
                                   </td>
                             </tr>
                           ) : ("")}
                           {only_worker_editable ? (
                             <tr>
                                  <th>
                                      <label>Refund/Overcharge Amount: </label>
                                  </th>
                                  <td>
                                      <input value={activities.refund_overcharge_amount} type="number" name="refund_overcharge_amount" onChange={handleChange} />
                                  </td>
                            </tr>
                          ) : ("")}
                           {only_admin_editable ? (
                             <tr>
                                  <th>
                                      <label>Work Quality Score (out of 10): </label>
                                  </th>
                                  <td>
                                      <input value={activities.work_quality_score} type="text" name="work_quality_score" onChange={handleChange} />
                                  </td>
                              </tr>
                            ) : ("")}
                            {only_admin_editable ? (
                              <tr>
                                   <th>
                                       <label>Maintenance Worker Initial Estimates Correctness Score (out of 10): </label>
                                   </th>
                                   <td>
                                       <input value={activities.worker_initial_estimate_correctness_score} type="text" name="worker_initial_estimate_correctness_score" onChange={handleChange} />
                                   </td>
                               </tr>
                             ) : ("")}
                            {only_admin_editable ? (
                              <tr>
                                  <th>
                                      <label>Work Complete InTime Score (out of 10): </label>
                                  </th>
                                  <td>
                                      <input value={activities.complete_in_time_score} type="text" name="complete_in_time_score" onChange={handleChange} />
                                  </td>
                              </tr>
                          ) : ("")}
                            {only_admin_editable ? (
                              <tr>
                                  <th>
                                      <label>Least Charging Score (out of 10): </label>
                                  </th>
                                  <td>
                                      <input value={activities.least_charge_score} type="text" name="least_charge_score" onChange={handleChange} />
                                  </td>
                              </tr>
                          ) : ("")}
                          {only_admin_editable ? (
                            <tr>
                                 <th>
                                     <label>Admin Feedback On Work And Reason Of Scores<br />(500 words Max.): </label>
                                 </th>
                                 <td>
                                     <textarea value={activities.final_comments_by_admin} name="final_comments_by_admin" onChange={handleChange} />
                                 </td>
                             </tr>
                           ) : ("")}
                           {only_admin_editable ? (
                             <tr>
                                  <th>
                                      <label>Lock editing: </label>
                                  </th>
                                  <td>
                                      <select value={activities.lock_editting} name="lock_editting" onChange={handleChange} defaultValue={'DEFAULT'} >
                                        <option value="DEFAULT">Select</option>
                                        <option value="yes">Yes</option>
                                      </select>
                                  </td>
                              </tr>
                            ) : ("")}
                         <tr>
                               <th>
                                   <label>Created On: </label>
                               </th>
                               <td>
                                   <div>{activities.created_at}</div>
                               </td>
                           </tr>
                         <tr>
                              <th>
                                  <label>Updated On: </label>
                              </th>
                              <td>
                                  <div>{activities.updated_at}</div>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan="2" align ="right">
                                  <button>Save</button>
                              </td>
                          </tr>
                      </tbody>
                  </> ) : (
                      <tbody>
                      <tr>
                           <th>
                               <label>Work ID: </label>
                           </th>
                           <td>
                               <div> {activities.work_id} </div>
                           </td>
                       </tr>
                       <tr>
                            <th>
                                <label>Problem Photo: </label>
                            </th>
                            <td><div> {activities.work_id} </div>
                                <img src={baseURL+"/images/maintenance_images/"+activities.problem_photos} alt="activity_images" width="60" height="60" />
                            </td>
                      </tr>
                        <tr>
                             <th>
                                 <label>Manual Inspection By: </label>
                             </th>
                             <td><div> {activities.manual_inspection_by} </div>
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Solution Approved By Admin<br />(200 words Max.): </label>
                              </th>
                              <td><div> {activities.solution_approved} </div>
                              </td>
                          </tr>
                           <tr>
                                <th>
                                    <label>Amount Approved By Admin: </label>
                                </th>
                                <td><div> {activities.amount_approved} </div>
                                </td>
                            </tr>
                          <tr>
                               <th>
                                   <label>Work Status: </label>
                               </th>
                               <td><div> {activities.work_status} </div>
                               </td>
                           </tr>
                           <tr>
                                <th>
                                    <label>Explain Solution Done<br />(200 words Max.): </label>
                                </th>
                                <td><div> {activities.solution_done} </div>
                                </td>
                          </tr>
                          <tr>
                               <th>
                                   <label>Actual Spent (Fill at end): </label>
                               </th>
                               <td><div> {activities.actual_spent} </div>
                               </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Refund/Overcharge Amount: </label>
                              </th>
                              <td><div> {activities.refund_overcharge_amount} </div>
                              </td>
                        </tr>
                         <tr>
                              <th>
                                  <label>Work Quality Score (out of 10): </label>
                              </th>
                              <td><div> {activities.work_quality_score} </div>
                              </td>
                          </tr>
                          <tr>
                               <th>
                                   <label>Maintenance Worker Initial Estimates Correctness Score (out of 10): </label>
                               </th>
                               <td><div> {activities.worker_initial_estimate_correctness_score} </div>
                               </td>
                           </tr>
                          <tr>
                              <th>
                                  <label>Work Complete InTime Score (out of 10): </label>
                              </th>
                              <td><div> {activities.complete_in_time_score} </div>
                              </td>
                          </tr>
                          <tr>
                              <th>
                                  <label>Least Charging Score (out of 10): </label>
                              </th>
                              <td><div> {activities.least_charge_score} </div>
                              </td>
                          </tr>
                        <tr>
                             <th>
                                 <label>Admin Feedback On Work And Reason Of Scores<br />(500 words Max.): </label>
                             </th>
                             <td><div> {activities.final_comments_by_admin} </div>
                             </td>
                         </tr>
                         <tr>
                              <th>
                                  <label>Lock editing: </label>
                              </th>
                              <td><div> {activities.lock_editting} </div>
                              </td>
                          </tr>
                      <tr>
                           <th>
                               <label>Created On: </label>
                           </th>
                           <td><div> {activities.created_at} </div>
                           </td>
                       </tr>
                      <tr>
                          <th>
                              <label>Updated On: </label>
                          </th>
                          <td><div> {activities.updated_at} </div>
                          </td>
                      </tr>
                      <tr>
                          <td colSpan="2" align ="right">
                              <button>Save</button>
                          </td>
                      </tr>
                      </tbody>
                  )}
                </table>
            </form>
          </div>
        </div>
    )
}
