import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'
import HRMenubar from "./../../menubars/HRMenubar"

export function CreateUser() {
  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else if(designation !== 'human_resource'){
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  const [inputs, setInputs] = useState({})
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file, setFile] = useState('')
  const handleFile = (event) => {
  	const file = event.target.files[0]

  	setFile({
  		picturePreview: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file1, setFile1] = useState('')
  const handleFile1 = (event) => {
  	const file1 = event.target.files[0]

  	setFile1({
  		picturePreview1: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile1: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file1.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file2, setFile2] = useState('')
  const handleFile2 = (event) => {
  	const file2 = event.target.files[0]

  	setFile2({
  		picturePreview2: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile2: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file2.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file3, setFile3] = useState('')
  const handleFile3 = (event) => {
  	const file3 = event.target.files[0]

  	setFile3({
  		picturePreview3: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile3: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file3.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file4, setFile4] = useState('')
  const handleFile4 = (event) => {
  	const file4 = event.target.files[0]

  	setFile4({
  		picturePreview4: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile4: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file4.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const[file5, setFile5] = useState('')
  const handleFile5 = (event) => {
  	const file5 = event.target.files[0]

  	setFile5({
  		picturePreview5: URL.createObjectURL(event.target.files[0]),
  		pictureAsFile5: event.target.files[0]
  	})

    const name = event.target.name;
    const value = file5.name;

    setInputs(values => ({...values, [name]: value}));
  }

  const c_idRef = useRef(null);

  const handleSubmit = (event) => {

    event.preventDefault();

    let formData = new FormData();
  	formData.append('file_attachment', file.pictureAsFile)
    formData.append('file_attachment1', file1.pictureAsFile1)
    formData.append('file_attachment2', file2.pictureAsFile2)
    formData.append('file_attachment3', file3.pictureAsFile3)
    formData.append('file_attachment4', file4.pictureAsFile4)
    formData.append('file_attachment5', file5.pictureAsFile5)
    formData.append('file_type', 'candidates')
    formData.append('c_id', c_idRef.current.value)

    var value1 = document.getElementById("dropdown_id1");
    var getvalue1 = value1.options[value1.selectedIndex].value;

    var value2 = document.getElementById("dropdown_id2");
    var getvalue2 = value2.options[value2.selectedIndex].value;

    var value3 = document.getElementById("dropdown_id3");
    var getvalue3 = value3.options[value3.selectedIndex].value;

    if(getvalue1 === "DEFAULT" || getvalue2 === "DEFAULT" || getvalue3 === "DEFAULT"){
      alert("Fill required fields.")
    } else {

      axios({
        url: '/upload_file.php',
        method:'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      }).then(function(response){
        if(response.data.status === 0){
          alert(response.data.message)
        } else {
          axios.post('/user/save', inputs).then(function(response){
            console.log(response.data);
            navigate('/users/listusers/'); //After saving, redirect to list of users
          });
        }
      })
    }
  }

  return (
    <div className="pagePadding">
      <div className="left">
        <HRMenubar />
      </div>
      <h1>Create User</h1>
      <div>
        <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
            <tbody>
                <tr>
                    <th>
                        <label>CandidateId*: </label>
                    </th>
                    <td>
                        <input type="text" ref={c_idRef} name="candidate_id" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Username*: </label>
                   </th>
                   <td>
                       <input type="text" name="username" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Profile Photo<br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="profile_photo" onChange={handleFile} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Designation*: </label>
                    </th>
                    <td>
                      <select name="designation" onChange={handleChange} id="dropdown_id1" defaultValue={'DEFAULT'}>
                        <option value="DEFAULT" disabled>Choose a Designation.</option>
                        <option value="admin">Admin</option>
                        <option value="project_manager">Project Manager</option>
                        <option value="human_resource">Human Resource</option>
                        <option value="team_leader">Team Leader</option>
                        <option value="software_developer">Software Developer</option>
                        <option value="software_designer">Software Designer</option>
                        <option value="bidder">Bidder</option>
                        <option value="maintenance_worker">Maintenance Worker</option>
                      </select>
                    </td>
                </tr>
                <tr>
                     <th>
                         <label>Team Allocated: </label>
                     </th>
                     <td>
                       <select name="team_allocated" onChange={handleChange} id="dropdown_id2" defaultValue={'DEFAULT'}>
                         <option value="DEFAULT" disabled>Choose a Team.</option>
                         <option value="alpha">Alpha</option>
                         <option value="beta">Beta</option>
                         <option value="gamma">Gamma</option>
                         <option value="delta">Delta</option>
                         <option value="bidder">Bidder</option>
                         <option value="team_leader">Team Leader</option>
                         <option value="human_resource">Human Resource</option>
                         <option value="maintenance_worker">Maintenance Worker</option>
                         <option value="security_guard">Security Guard</option>
                         <option value="admin">Admin</option>
                         <option value="project_manager">Project Manager</option>
                       </select>
                     </td>
                 </tr>
                <tr>
                    <th>
                        <label>Skills*: </label>
                    </th>
                    <td>
                        <input type="text" name="skills" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>All Work Experience with Years*: </label>
                   </th>
                   <td>
                       <textarea name="experience" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>All Education after Metric*: </label>
                    </th>
                    <td>
                        <textarea name="education" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>List all Documents submitted*: </label>
                   </th>
                   <td>
                       <textarea name="documents" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Resume<br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="resume" onChange={handleFile1} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Adhaar Card <br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="adhaar_card" onChange={handleFile2} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>PAN Card <br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="pan_card" onChange={handleFile3} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Highest qualification document<br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="qualification_document" onChange={handleFile4} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Last company Experience Certificate (if not, add Training Certificate)<br />(Compress image below 50KB <a href="https://www.imgonline.com.ua/eng/compress-image-size.php" target="_blank" rel="noreferrer">here</a>)*: </label>
                   </th>
                   <td>
                       <input type="file" name="experience_training" onChange={handleFile5} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Salary discussed*: </label>
                    </th>
                    <td>
                        <input type="text" name="salary_discussed" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Email*: </label>
                   </th>
                   <td>
                       <input type="email" name="email" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Phone*: </label>
                    </th>
                    <td>
                        <input type="tel" name="mobile" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Other Phone: </label>
                   </th>
                   <td>
                       <input type="tel" name="other_phone" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                   <th>
                       <label>Date Of Birth*: </label>
                   </th>
                   <td>
                       <input type="date" name="date_of_birth" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Home Address*: </label>
                    </th>
                    <td>
                        <input type="text" name="home_address" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Correspondence Address: </label>
                   </th>
                   <td>
                       <input type="text" name="correspondence_address" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Status*: </label>
                    </th>
                    <td>
                    <select value={inputs.status} name="status" id="dropdown_id3" onChange={handleChange} defaultValue={'DEFAULT'} >
                      <option value="DEFAULT" disabled>Choose a Type.</option>
                      <option value="not_joined_yet">Not Joined Yet</option>
                      <option value="active">Actively Working</option>
                      <option value="left">Left</option>
                    </select>
                    </td>
                </tr>
                <tr>
                   <th>
                       <label>Company left date: </label>
                   </th>
                   <td>
                       <input type="text" name="left_date" onChange={handleChange} required />
                   </td>
                </tr>
                <tr>
                    <th>
                        <label>Password*: </label>
                    </th>
                    <td>
                        <input type="text" name="password" onChange={handleChange} required />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" align ="right">
                        <button>Save</button>
                    </td>
                </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  )
}
