import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios.js'

export function DeleteAttendance() {
  const navigate = useNavigate();

  const inputRef = useRef(null);

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const deleteAtten = (id) => {
          axios.delete(`/attendance.php/${id}/delete`).then(function(response){
          //console.log(response.data);
          navigate('/users/listattendance');
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  if(designation === 'assistant'){
    return (
      <div className="pagePadding">
        <h1>
          Delete Last Attendance
        </h1>
        <div>
          <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
              <tbody>
                  <tr>
                      <th>
                          <label>Select Employee ID*: </label>
                      </th>
                      <td>
                        <select name="e_id" ref={inputRef} defaultValue={'DEFAULT'} id="dropdown_id1" >
                          <option value="DEFAULT" disabled>Select</option>
                          {users
                            .sort((a, b) => a.employee_id - b.employee_id)
                            .map((user, key) =>
                            <option key={key} value={"eid_"+user.employee_id}>{user.employee_id} {user.username} {user.designation}</option>
                          )}
                        </select>
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="2" align ="right">
                          <div><button onClick={() => deleteAtten(inputRef.current.value)}>Delete</button></div>
                      </td>
                  </tr>
              </tbody>
            </table>
            <br />
            <center><b>Important:</b> Previous months' attendance can't be deleted from here.</center>
          </form>
        </div>
      </div>
    )
  }
}
