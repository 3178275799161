import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios.js'
import ReactPaginate from 'react-paginate'

export function ListProjects() {
  const[projects,setProjects] = useState([]);

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  var editable = false
  if(designation === "team_leader" || designation === "project_manager" || designation === "assistant" ){
    editable = true;
  }

  var editable_only_by_assistant = false
  if(designation === "assistant"){
    editable_only_by_assistant = true;
  }

  useEffect(() => {
    if(loggedIn === null){
      navigate('/login');
    }
    else {
      getProjects();
    }
  }, [loggedIn, navigate]);

  function getProjects() {
        axios.get('/projects.php').then(function(response){
        //console.log(response.data);
        setProjects(response.data);
    });
  }

  const deleteProject = (id) => {
          axios.delete(`/projects.php/${id}/delete`).then(function(response){
          //console.log(response.data);
          getProjects();
      });
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  const[pageNumber, setPageNumber] = useState(0)
  const projectsPerPage = 5
  const pageVisited = pageNumber * projectsPerPage
  const pageCount = Math.ceil(users.length / projectsPerPage)
  const changePage = ({selected}) => {
      setPageNumber(selected)
  }

  const displayProjects = projects
    //.filter(project => project.project_assigned_to_tl === employee_id)
  .slice(pageVisited, pageVisited + projectsPerPage)
  .map((project, key) =>
    <tr key={key}>
      {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
      {project.project_assigned_to_employee === employee_id || project.project_assigned_to_tl === employee_id || project.project_bid_by === employee_id || designation === 'project_manager' || designation === 'assistant' ? ( <>
        <td>{project.id}</td>
        <td>{project.project_name}</td>
        <td>{project.client_name}</td>
        <td>{project.project_details}</td>
        <td>{project.hourly_fixed_price}</td>
        <td>{project.amount}</td>
        <td>{project.noc_required}</td>
        {users
          .filter(user => user.employee_id === project.project_bid_by)
          .map((user, key) =>
          <td key={key}>{user.username} {user.employee_id} {user.designation}</td>
        )}
        <td>{project.fresh_client_or_recurring_client}</td>
        <td>{project.project_source_platform}</td>
        <td>{project.project_assign_date}</td>
        {users
          .filter(user => project.project_assigned_to_tl === user.employee_id)
          .map((user, key) =>
          <td key={key}>{user.team_allocated}</td>
        )}
        {users
          .filter(user => user.employee_id === project.project_assigned_to_tl)
          .map((user, key) =>
          <td key={key}>{user.username} {user.employee_id} {user.designation}</td>
        )}
        {editable ? (
          <>
            <td>
              {users
                .filter(user => user.employee_id === project.project_assigned_to_employee)
                .map((user, key) =>
                <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
              )}
            </td>
            <td>{project.project_final_feedback_date}</td>
            <td>{project.project_feedback_score_on_platform_overall_percentage}</td>
            <td>{project.tl_feedback_description}</td>
            <td>{project.created_at}</td>
            <td>{project.updated_at}</td>
            <td>
              <div><Link to={`project/${project.id}/edit`} style={{marginRight: "10px"}}>Edit</Link></div>
              {editable_only_by_assistant ? ( <>
                <div><button onClick={() => deleteProject(project.id)}>Delete</button></div>
              </> ) : ("") }
            </td>
        </> ) : ("") }
      </> ) : ("")}
    </tr>
  )

  if(designation === 'assistant' || designation === 'project_manager' || designation === 'team_leader'){
    return (
      <div className="pagePadding">
        <h1>List Projects</h1>
        <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Project Name</th>
                <th>Client Name</th>
                <th>Project Details</th>
                <th>Hourly Fixed Price</th>
                <th>Amount</th>
                <th>NOC Required</th>
                <th>Project Bid/Created By</th>
                <th>Fresh/Recurring Client</th>
                <th>Source Platform</th>
                <th>Assigned Date</th>
                <th>Assigned To Team</th>
                <th>Assigned To TL</th>
                {editable ? (
                  <>
                    <th>Assigned To Employee</th>
                    <th>Client Feedback Date</th>
                    <th>Client Feedback %</th>
                    <th>TL Feedback Description</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </>
                  ) : ("")
                }
              </tr>
            </thead>
            <tbody>
              {displayProjects}
            </tbody>
          </table>
          <ReactPaginate
          	previousLabel={"Previous"}
          	nextLabel={"Next"}
          	pageCount={pageCount}
          	onPageChange={changePage}
          	containerClassName={"paginationButtons"}
          	previousLinkClassName={"privousButton"}
          	nextLinkClassName={"nextButton"}
          	disabledClassName={"paginationDisabled"}
          	activeClassName={"paginationActive"}
          />
        </div>
      </div>
    )
  }
}
