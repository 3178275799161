import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios.js';

function UserProjects() {

  const navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var username = sessionStorage.getItem("username")
  var designation = sessionStorage.getItem("designation")
  var employee_id = sessionStorage.getItem("employee_id")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
  }, [loggedIn, navigate]);

  // eslint-disable-next-line
  {/*To get projects info, from database*/}
  const[projects,setProjects] = useState([]);
  useEffect(() => {
      getProjects();
  }, [loggedIn, designation, navigate]);
  function getProjects() {
        axios.get('/projects.php').then(function(response){
        //console.log(response.data);
        setProjects(response.data);
    });
  }

  var only_for_project_manager = false
  if(designation === "project_manager"){
    only_for_project_manager = true;
  }

  var not_for_bidder = true
  if(designation === "bidder"){
    not_for_bidder = false;
  }

  var not_for_employee = true
  if(designation === "software_developer" || designation === "software_designer"){
    not_for_employee = false;
  }

  // eslint-disable-next-line
  {/*To display employee in dropdown, from database*/}
  const[users,setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);
  function getUsers() {
    axios.get('/users').then(function(response){
        //console.log(response.data);
        setUsers(response.data);
    });
  }

  if(designation === 'assistant' || designation === 'project_manager' || designation === 'team_leader' || designation === 'bidder' || designation === 'software_developer' || designation === 'software_designer'){
    return (
      <>
          <div className="right"> <b>Welcome {username}</b> </div>
          <br />
          <div className="right"> <b>{designation}</b> </div>
          <br />
          <div className="right"> <b>Employee Id:</b> {employee_id} </div>
          <br />
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Project Name</th>
                {only_for_project_manager ? ( <>
                  <th>Project Details</th>
                </> ) : ("")}
                <th>Client Name</th>
                <th>Hourly Fixed Price</th>
                {only_for_project_manager ? ( <>
                  <th>Amount</th>
                </> ) : ("")}
                <th>NOC Required</th>
                {not_for_bidder ? ( <>
                  <th>Project Bid/Created By</th>
                </> ) : ("")}
                {not_for_bidder ? ( <>
                  <th>Fresh/Recurring Client</th>
                </> ) : ("")}
                <th>Source Platform</th>
                <th>Assigned Date</th>
                <th>Assigned To Team</th>
                {only_for_project_manager ? ( <>
                  <th>Assigned To TL</th>
                </> ) : ("")}
                {not_for_bidder ? ( <>
                  <th>Assigned To Employee</th>
                </> ) : ("")}
                {only_for_project_manager ? ( <>
                  <th>Client Feedback Date</th>
                </> ) : ("")}
                {not_for_employee ? ( <>
                  <th>Client Feedback %</th>
                </> ) : ("")}
                {only_for_project_manager ? ( <>
                    <th>TL Feedback Description</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                </> ) : ("")}
              </tr>
            </thead>
            <tbody>
              {projects.map((project, key) =>
                <tr key={key}>
                  {/*if project employee id, tl's employee id or bidder's employee id of projects is same as logged in user's employee_id, show only projects related to that id, or if logged in 'designation' is 'project_manager', show all projects */}
                  {project.project_assigned_to_employee === employee_id || project.project_assigned_to_tl === employee_id || project.project_bid_by === employee_id || designation === 'project_manager' ? ( <>
                    <td>{project.id}</td>
                    <td>{project.project_name}</td>
                    {only_for_project_manager ? ( <>
                      <td>{project.project_details}</td>
                    </> ) : ("")}
                    <td>{project.client_name}</td>
                    <td>{project.hourly_fixed_price}</td>
                    {only_for_project_manager ? ( <>
                      <td>{project.amount}</td>
                    </> ) : ("")}
                    <td>{project.noc_required}</td>
                    {not_for_bidder ? ( <>
                      {users
                        .filter(user => user.employee_id === project.project_bid_by)
                        .map((user, key) =>
                        <td key={key}>{user.username} {user.employee_id} {user.designation}</td>
                      )}
                      <td>{project.fresh_client_or_recurring_client}</td>
                    </> ) : ("")}
                    <td>{project.project_source_platform}</td>
                    <td>{project.project_assign_date}</td>
                    <td>{project.project_assigned_to_team}</td>
                    {only_for_project_manager ? ( <>
                      <td>
                      {users
                        .filter(user => user.employee_id === project.project_assigned_to_tl)
                        .map((user, key) =>
                        <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                      )}
                      </td>
                    </> ) : ("")}
                    {not_for_bidder ? ( <>
                      <td>
                      {users
                        .filter(user => user.employee_id === project.project_assigned_to_employee)
                        .map((user, key) =>
                        <div key={key}>{user.username} {user.employee_id} {user.designation}</div>
                      )}
                      </td>
                    </> ) : ("")}
                    {only_for_project_manager ? ( <>
                      <td>{project.project_final_feedback_date}</td>
                    </> ) : ("")}
                    {not_for_employee ? ( <>
                      <td>{project.project_feedback_score_on_platform_overall_percentage}</td>
                    </> ) : ("")}
                    {only_for_project_manager ? ( <>
                      <td>{project.tl_feedback_description}</td>
                      <td>{project.created_at}</td>
                      <td>{project.updated_at}</td>
                    </> ) : ("")}
                  </> ) : ("")}
                </tr>
              )}
            </tbody>
          </table>
      </>
    )
  }
}

export default UserProjects;
