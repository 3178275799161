import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function HRInfobar() {

  var navigate = useNavigate();

  var loggedIn = sessionStorage.getItem("loggedIn")
  var designation = sessionStorage.getItem("designation")

  useEffect(() => {
    if(loggedIn === null) {
      navigate('/login');
    }
    else if(designation !== 'human_resource') {
      navigate('/');
    }
  }, [loggedIn, designation, navigate]);

  return (
    <>
      <div className="changeBackground pad1px"> HR Main Duties (Read daily):
        <li>Maintain attendance on register and regularly update attendance on Management System.</li>
        <li>Update new employees documents, company documents, appraisal letter, joining letter.</li>
        <li>Update all payment requirements by employees after verifying and request payment to Admin.</li>
        <li>Pay employees from payment account approved by Admin.</li>
        <li>Communicate to TL if Project Manager or Admin sends warning for employee performance or activities.</li>
        <li>In case of firing, call employee in office to discuss, they need to work one month extra, etc.</li>
        <li>Update latest notification in Management system.</li>
        <li>Hear issues of workers, report major monetary issues to Admin and Employee issues to Project Manager. Fix minor issues by own with help of TL but make proper reports of all activities done.</li>
        <li>Always follow orders of Admin and Project Manager.</li>
        <li>Project Manager or Admin can tell to warn or hire/fire employees. Can forward this task to relevant Team Leader, should be done in one working day.</li>
        <li>Check if Admin updates status for user, documents issue. If approved by Project Manager, can contact candidate for documents.</li>
        <li>After approval from Project Manager and Admin, tell candidate about selection on phone. And add an employee_id for him, along with other details</li>
        <li>For new employees, make sure team allocated to employee is same as Team Leader's team, under whom worker will work. Update Status to Active, Add employee ID, etc.</li>
        <li>Daily do your daily activity entry at end of day.</li>
        <li>Daily check all employees did daily activity entry at end of day. Can complain Team Leader for missing or early entry and entry mistakes of team workers.</li>
        <li>To delete any entry, request Assistant by <Link to="/contact">message</Link>, with reason of issue and only after making similar correct entry.</li>
        <li>Only mode of communication allowed in company is through <Link to="/contact">message</Link>, to get any work done, so that each activity get noted.</li>
        <li>Note attendance on paper first, on basis of present, half_day, quarter_day, leave and absent; update it online at the beginning of next day, after verifying everyone done 'Daily Activities Entry' yesterday.</li>
        <li>Any oneone missed 'Daily Activity Entry', wait until they make entry for yesterday, then mark their attendance.</li>
        <li>Daily check head count of workers (or ask responsible Team Leaders), to check if after taking 'half day leave' or 'short leave', workers were present rest of the day. If not, mark them fully Absent.</li>
        <li>To manage above point, can create daily attendance register (with time-in and time-out), and verify while sitting in front, other employees don't do entry of others.</li>
        <li>Employees coming late or going early should be warned seriously, to stay in time table or note their entry/exit time and mark them Absent for a day, only after informing them less hours worked in past.</li>
        <li>To delete latest attendance entries for an employee id, contact 'company Assistant' through <Link to="/contact">message</Link>. Previous months' attendance can't be deleted.</li>
        <li>Only one person from a team can take leave on one day, so that whole team don't go on leave. Tell the responsible team leader to manage team leaves, in advance.</li>
        <li>If anyone leaving company, they should inform one month in advance (so that company can hire replacement), otherwise deduct their pending salary(inform Admin about details).</li>
        <li>If anyone absent for three days regularly. They will be warned on phone and marked as 'left company'. They can join back after interview and with new employee_id, only after approval of Admin and Project Manager.</li>
        <li>Any fine taken must be mentioned in daily entry, informed to <Link to="/contact">Admin</Link> and submitted to company bank within three working days.</li>
        <li>Bonus is not allowed, but only adjustment of absent from unpaid salary. Contact <Link to="/contact">Assistant</Link> to mark a present for employee, with employee Id and reason.</li>
      </div>
    </>
  )

  }

  export default HRInfobar;
